import _ from "lodash"
import piniaPersistConfig from "@/config/piniaPersist";
import {defineStore} from "pinia";
import {SettingState} from "@/stores/interface/setting";
import {EnumFontSize} from "@/enum/font";

const id: string = "SettingStore";

/**
 * 用戶設置
 */
export const SettingStore = defineStore({
    id: id,
    state: (): SettingState => ({
        organization: 1,
        token: null,
        theme: false,
        follow: false,
        system: false,
        region: "zhTW",
        secondFromGMT: 28800,
        supplier: null,
        fontSize: EnumFontSize.middle,
        articleComment: true,
        passCode: false,
        version: null,
        bundle: null
    }),
    getters: {
        isDarkTheme: state => {
            return state.theme
        },
        isFollowTheme: state => {
            return state.follow
        },
        isSystemDarkTheme: state => {
            return state.system
        },
        getRegion: state => {
            return state.region
        },
        getOrganization: state => {
            return state.organization
        },
        getToken: state => {
            return state.token
        },
        getSecondFromGMT: state => {
            return state.secondFromGMT
        },
        getSupplier: state => {
            return state.supplier
        },
        getFontSize: state => {
            return state.fontSize
        },
        isArticleComment: state => {
            return state.articleComment
        },
        isPassCode: state => {
            return state.passCode
        },
        getVersion: state => {
            return state.version
        },
        getBundle: state => {
            return state.bundle
        }
    },
    actions: {
        /**
         * ⚠️⚠️ 注意 ⚠️⚠️
         * JS交互給的是 string 要轉換 boolean
         */
        setTheme(value: any) {
            this.theme = (typeof value === "boolean" ? value : _.lowerCase(value) === 'true')
        },
        /**
         * ⚠️⚠️ 注意 ⚠️⚠️
         * JS交互給的是 string 要轉換 boolean
         */
        setFollowTheme(value: any) {
            this.follow = (typeof value === "boolean" ? value : _.lowerCase(value) === 'true')
        },
        /**
         * ⚠️⚠️ 注意 ⚠️⚠️
         * JS交互給的是 string 要轉換 boolean
         */
        setSystemTheme(value: any) {
            this.system = (typeof value === "boolean" ? value : _.lowerCase(value) === 'true')
        },
        setOrganization(value: number) {
            this.organization = value;
        },
        setRegion(value: string) {
            this.region = value;
        },
        setToken(value: string | null) {
            this.token = value;
        },
        setSecondFromGMT(value: number) {
            this.secondFromGMT = value
        },
        setSupplier(value: string | null) {
            this.supplier = value;
        },
        setFontSize(value: EnumFontSize) {
            this.fontSize = value;
        },
        setArticleComment(value: boolean) {
            this.articleComment = value;
        },
        setPassCode(value: boolean) {
            this.passCode = value;
        },
        setVersion(value: string) {
            this.version = value
        },
        setBundle(value: string) {
            this.bundle = value
        }
    },
    persist: piniaPersistConfig(id)
});
