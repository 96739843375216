export default {
    record: {
        edit: {
            nav: {
                subject: "紀錄內容"
            },
            field: {
                order: "我司單號",
                no: "交易單號",
                status: "支付狀態",
                amount: "奉獻金額",
                stamp: "建立時間",
                trade: "交易時間"
            },
            status: {
                waiting: "待支付",
                success: "支付完成",
                failure: "支付失敗",
                cancel: "取消支付"
            }
        },
        tab: {
            all: "全部",
        },
        nav: {
            subject: "奉獻紀錄"
        },
        field: {
            order: "單號",
            amount: "金額",
            stamp: "時間"
        },
        status: {
            waiting: "待支付",
            success: "支付完成",
            failure: "支付失敗",
            cancel: "取消支付"
        }
    },
    buy: {
        connect: {
            subject: "正在連接藍新金流\n請耐心等候"
        },
        failure: {
            subject: "藍新金流返回異常",
            text: "請重新奉獻<br/>或請稍後再試"
        }
    },
    nav: {
        subject: "奉獻"
    },
    subject: "我想奉獻",
    holder: {
        subject: "請輸入奉獻金額"
    },
    handler: {
        submit: {
            newebpay: {
                subject: "即將前往藍新金流進行奉獻",
                text: "確定要前往嗎?"
            },
            hyperlink: {
                subject: "即將前往外部奉獻連結",
                text: "確定要前往嗎?"
            }
        }
    },
    description: {
        subject: "注意事項",
        other: {
            1: "使用奉獻服務建議先前往 ＞ 會員中心 ＞ 個資編輯, 填寫聯絡方式方便教會開立收據",
            2: "你所奉獻的金額全數奉獻給「%s」, 愛無界協會不經手辦理",
            3: "奉獻如有疑問或需奉獻徵信, 請洽教會負責人員",
            4: "線上奉獻交易服務由「藍新金流平台」提供"
        },
        101: {
            1: "奉獻資訊將定期公布於教會辦公室公告欄及主日周報",
            2: "各類奉獻，請參閱奉獻資訊頁面相關內容",
            3: "<ul><li>線上奉獻<li><li>定期定額滙款<li><li>國內單筆現金／支票<li><li>國外滙款奉獻<li></ul>",
            4: "請儘量使用定期定額銀行轉帳奉獻方式，以減少紙張及交易手續等成本",
            5: "",
            6: "若有任何問題請洽教會財務部同工",
            7: "洪小姐 (02-8101-2100 ext. 116)",
            8: "財務部專用電子郵件信箱",
            9: "accountant{'@'}iwillshare.org",
            10: "地址：台北市110 信義區信義路五段7號52樓C室"
        }
    },
    failure: {
        subject: "奉獻失敗"
    },
    submit: "前往奉獻"
}
