export default {
    subject: "活動提交",
    method: {
        name: {
            1: {
                subject: "個人報名"
            },
            2: {
                subject: "團體報名"
            }
        },
        restrict: {
            subject: "剩餘名額已經不足團體報名, 僅能進行個人報名"
        }
    },
    organization: {
        must: {
            subject: "必填"
        },
        empty: {
            subject: "未填寫"
        },
        field: {
            name: {
                subject: "教會(機構)名稱"
            },
            city: {
                subject: "教會(機構)所在城市"
            },
            contact: {
                name: {
                    subject: "聯絡人姓名"
                },
                phone: {
                    subject: "聯絡人電話"
                },
                email: {
                    subject: "聯絡人電子郵件"
                }
            },
            zip: {
                subject: "郵遞區號"
            },
            address: {
                subject: "通訊地址"
            }
        },
        holder: {
            name: {
                subject: "請輸入教會(機構)名稱"
            },
            city: {
                subject: "請輸入教會(機構)所在城市"
            },
            contact: {
                name: {
                    subject: "請輸入聯絡人姓名"
                },
                phone: {
                    subject: "請輸入聯絡人電話"
                },
                email: {
                    subject: "請輸入聯絡人電子郵件"
                }
            },
            zip: {
                subject: "請輸入郵遞區號"
            },
            address: {
                subject: "請輸入通訊地址"
            }
        },
        error: {
            email: {
                subject: "請輸入正確的電子郵件格式"
            }
        }
    },
    condition: {
        must: {
            subject: "必填"
        },
        empty: {
            subject: "未填寫"
        },
        holder: {
            boolean: {
                true: {
                    subject: "是"
                },
                false: {
                    subject: "否"
                }
            },
            text: {
                subject: "請輸入"
            },
            select: {
                subject: "請選擇"
            },
            append: {
                subject: "請輸入%s的描述"
            }
        },
        error: {
            email: {
                subject: "請輸入正確的電子郵件格式"
            }
        }
    },
    sheet: {
        field: {
            email: "電子郵件"
        },
        empty: {
            subject: "請先增加團體報名人員",
        },
        restrict: {
            subject: `團體報名人數最少 <b class="ft-16">%s</b> 位, 最多 <b class="ft-16">%s</b> 位`,
            left: {
                subject: `, 還需要 <b class="ft-16">%s</b> 位報名`
            },
            satisfy: {
                minimum: {
                    subject: `, 最多還可以報名 <b class="ft-16">%s</b> 位`
                },
                negative: {
                    subject: `, 已經達到活動報名人數上限`
                },
                maximum: {
                    subject: `已達到團報最高人數`
                }
            }
        },
        submit: {
            increase: {
                subject: "增加團報",
                text: "增加團體報名人員"
            }
        }
    },
    summary: {
        fieldset: {
            price: {
                subject: "費用",
                text: "合計 %s 位報名"
            },
            echelon: {
                subject: "場次"
            },
            organization: {
                subject: "團體報名資料"
            },
            data: {
                subject: "報名資料",
                text: "第 %s 位報名, 共 %s 位報名"
            }
        }
    },
    payment: {
        subject: "支付方式",
        method: {
            1: {
                subject: "信用卡"
            },
            2: {
                subject: "ATM"
            },
            3: {
                subject: "超商條碼"
            },
            4: {
                subject: "銀聯卡"
            }
        }
    },
    handler: {
        subject: "即將放棄提交報名",
        text: "放棄後填寫資料無法復原, 請確認後執行",
        payment: {
            subject: "即將前往藍新金流進行線上支付",
            text: "確定要前往嗎?"
        },
        success: {
            subject: "活動提交成功",
            text: null,
            query: {
                subject: "活動提交成功",
                text: `等待您的支付報名費用`,
                complete: {
                    subject: "活動提交成功",
                    text: "我們已經收到您線上支付的報名費用"
                }
            }
        }
    },
    error: {
        unknown: {
            subject: "線上支付異常"
        }
    }
}
