import activity from "@/language/modules/page/article/activity/zhTW"

export default {

    entry: {
        tab: {
            all: "全部",
        },
        my: {
            nav: "我的教會"
        },
        soul: {
            nav: "心靈捕手"
        },
        daily: {
            nav: "每日靈糧"
        }
    },
    edit: {

        activity: activity,

        alarm: {
            nav: {
                subject: "檢舉"
            },
            subject: {
                reason: "理由",
                feedback: "意見或建議"
            },
            holder: {
                feedback: "請輸入您的意見或建議(可留空)"
            },
            success: {
                subject: "檢舉成功"
            },
            failure: {
                subject: "檢舉失敗"
            },
            reason: [
                "濫發廣告",
                "騷擾行為",
                "其他"
            ]
        },
        holder:{
            comment: {
                subject: "說點什麼嗎"
            }
        },
        summary: {
            comment: {
                subject: "留言"
            },
            favorite: {
                subject: "收藏"
            },
            share: {
                subject: "分享"
            }
        },
        favorite: {
            put: {
                success: {
                    subject: "收藏成功"
                },
                failure: {
                    subject: "收藏失敗"
                }
            },
            delete: {
                success: {
                    subject: "回收成功"
                },
                failure: {
                    subject: "回收失敗"
                }
            }
        },
        comment: {
            empty: {
                subject: "還沒有任何留言"
            },
            more: {
                alarm: {
                    subject: "檢舉",
                    success: {
                        subject: "檢舉成功",
                        text: "我們已經收到您寶貴的意見並儘快審視\n感謝您寶貴的意見"
                    },
                    failure: {
                        subject: "檢舉失敗"
                    }
                },
                black: {
                    list: {
                        subject: "黑名單",
                        success: {
                            subject: "加入成功",
                            text: "您可以前往黑名單管理中\n解除這名會友的黑名單"
                        },
                        failure: {
                            subject: "加入失敗"
                        }
                    }
                },
                handler: {
                    alarm: {
                        subject: "即將檢舉這名會友",
                        text: "請確認後執行"
                    },
                    black: {
                        list: {
                            subject: "即將將這名會友加入黑名單",
                            text: "請確認後執行"
                        }
                    }
                }
            },
            success: {
                subject: "留言成功"
            },
            failure: {
                subject: "留言失敗"
            },
            like: {
                put: {
                    success: {
                        subject: "感謝您的點讚"
                    },
                    failure: {
                        subject: "點讚失敗"
                    }
                },
                delete: {
                    success: {
                        subject: "回收成功"
                    },
                    failure: {
                        subject: "回收失敗"
                    }
                }
            }
        },
        share: {
            failure: {
                subject: `糟糕\n發生錯誤`
            }
        },
        read: {
            subject: "發佈於 %s ・ 共計 %s 人觀看"
        },
        proceed: {
            live: {
                subject: "前往"
            },
            activity: {
                subject: "提交"
            }
        },
        more: {
            share: {
                subject: "分享"
            },
            favorite: {
                subject: {
                    enable: "收藏文章",
                    disable: "取消收藏"
                },
                update: {
                    enable: "收藏成功",
                    disable: "取消成功"
                }
            },
            theme: {
                subject: "切換主題"
            },
            font: {
                subject: "字體大小"
            },
            alarm: {
                subject: "檢舉"
            }
        }
    }
}
