import router from "@/router/router";

/**
 * 路由攔截
 */
router.beforeEach((to, from, next) => {
    return next()
})

router.afterEach(() => {
});

export default router;
