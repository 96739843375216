<template>
  <van-config-provider
      :class="isSupplierApple ? `is-apple-only` : `is-android-only`"
      :theme="getTheme">
    <router-view />
  </van-config-provider>
</template>

<script setup lang="ts">

import {ConfigProviderTheme} from "vant";
import {computed} from "vue";
import {useSetting} from "@/hooks/useSetting";

const {
    isDarkTheme,
    isFollowTheme,
    isSystemDarkTheme,
    isSupplierApple
} = useSetting()

/**
 * 返回 - 主題
 */
const getTheme = computed((): ConfigProviderTheme => {
    if (isFollowTheme.value) return isSystemDarkTheme.value ? "dark" : "light"
    return isDarkTheme.value ? "dark" : "light"
})

</script>

<style scoped lang="sass">
/** 除掉 vant4 內建的頂部添加單位 **/
@supports (top: 0)
  #app
    padding-top: 0 !important
</style>
