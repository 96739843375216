import buy from "@/language/modules/page/article/activity/buy/zhTW"
import charge from "@/language/modules/page/article/activity/charge/zhTW"
import discount from "@/language/modules/page/article/activity/discount/zhTW"
import echelon from "@/language/modules/page/article/activity/echelon/zhTW"
import register from "@/language/modules/page/article/activity/register/zhTW"

export default {

    buy: buy,

    charge: charge,

    discount: discount,

    echelon: echelon,

    register: register,

    apply: {
        subject: `已有 <b class="ft-14 text-success">%s</b> 人報名`,
        left: {
            subject: `還有 <b class="ft-14 text-danger">%s</b> 個名額`,
        },
        full: {
            subject: `名額已滿<br/>共計 <b class="ft-14 text-success">%s</b> 人報名`,
        }
    },

    note: {
        subject: "活動內容"
    }

}
