export default {
    subject: "報名費用",
    free: {
        subject: "免費報名"
    },
    original: {
        subject: "費用"
    },
    discount: {
        subject: "優惠費用"
    }
}
