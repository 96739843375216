import rank from "@/language/modules/page/attend/rank/zhCN"
import rollcall from "@/language/modules/page/attend/rollcall/zhCN"
import signature from "@/language/modules/page/attend/signature/zhCN"

export default {
    nav: {
        subject: "聚会内容"
    },
    field: {
        begin: {
            subject: "开始时间"
        },
        end: {
            subject: "结束时间"
        },
        location: {
            subject: "地点"
        },
        note: {
            subject: "备注"
        }
    },
    read: {
        subject: "发布时间 %s"
    },
    edit: {
        register: {
            nav: {
                subject: "聚会签到"
            },
            notice: {
                subject: "聚会需要在开始之后才可以进行点名"
            },
            read: {
                subject: "发布时间 %s"
            },
            field: {
                begin: {
                    subject: "开始时间"
                },
                end: {
                    subject: "结束时间"
                },
                location: {
                    subject: "地点"
                },
                note: {
                    subject: "备注"
                }
            },
            submit: {
                register: "递交签到"
            },
            success: {
                subject: "递交成功"
            },
            failure: {
                subject: "递交失败"
            },
            handler: {
                submit: {
                    subject: "即将进行递交签到",
                    text: "请确认后执行"
                }
            },
            client: {
                nav: {
                    subject: "聚会报到"
                },
                notice: {
                    subject: "聚会需要在开始之后才可以进行点名"
                },
                read: {
                    subject: "发布时间 %s"
                },
                client: {
                    subject: "签到会友"
                },
                field: {
                    begin: {
                        subject: "开始时间"
                    },
                    end: {
                        subject: "结束时间"
                    },
                    location: {
                        subject: "地点"
                    },
                    note: {
                        subject: "备注"
                    }
                },
                submit: {
                    register: "递交签到"
                },
                success: {
                    subject: "递交成功"
                },
                failure: {
                    subject: "递交失败"
                },
                handler: {
                    submit: {
                        subject: "即将进行递交签到",
                        text: "请确认后执行"
                    }
                }
            }
        }
    },
    rank: rank,
    rollcall: rollcall,
    signature: signature
}
