<template>
  <div class="begin">
    <div class="head">
      <img alt="" src="/img/launch.png" />
      <h4 class="text-dark subject">就是為了你</h4>
      <h5 class="text-muted text">Just For You</h5>
    </div>
    <div class="foot">
      <h5 class="mb-1 text-secondary" v-html="$t(`${active.i18n}.subject`)" />
      <div class="setup">
        <a target="_blank" href="https://apps.apple.com/tw/app/wo-de-jiao-hui/id1091176017?l=zh">
          <img alt="" src="/img/app/apple.webp">
        </a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.justforthee.ichurch&hl=zh_TW">
          <img alt="" src="/img/app/android.webp">
        </a>
      </div>
      <h4 class="subject">社團法人中華愛無界全人關懷協會</h4>
      <p class="text text-secondary">Unbounded Love Humanity Care Chinese Nonprofit Association</p>
    </div>
  </div>
</template>

<script setup lang="ts">

import {nextTick, onMounted, reactive} from "vue";
import {useJsBridge} from "@/hooks/useJsBridge";

const {
  onError404,
  onNextTick
} = useJsBridge()

/**
 * 參數 - 本地
 */
const active = reactive<{
  i18n: string
}>({
  i18n: "page.launch",
})

/**
 * 生命週期 - 掛載
 */
onMounted(async () => {

  await nextTick()

  //⚠️⚠️ 注意 ⚠️⚠️
  //避免開發過程中, 載入到需要token的頁面, 沒提供token
  //app只會在那轉圈圈, 也沒有 js 交互訊息
  //呼叫 app 把頁面打開, 就看得出來了
  onNextTick()

  //呼叫 - 錯誤 - 404
  onError404()

})

</script>

<style scoped lang="sass">

@import '@/style/core/variables'

.begin
  height: calc(100vh - 2rem)
  padding: 1rem
  background: map-get($colorLight, "background1")
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column
  .head
    width: 100%
    height: 100%
    text-align: center
    margin-top: 6rem
    .subject
      margin-top: .5rem
    img
      width: 180px
      aspect-ratio: 1/1
  .foot
    width: 100%
    text-align: center
    .setup
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 3rem
      a
        width: 30%
        &:first-of-type
          margin-right: 1rem
        img
          border-radius: .375rem
          width: 100%
    .subject
      color: #5975AC
      text-align: justify
      font-size: 24px
      text-align-last: center
    .text
      font-size: 11px
      text-align: justify
      text-align-last: center

.van-theme-dark
  .begin
    background: map-get($colorDark, "background2")
</style>
