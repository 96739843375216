export default {
    nav: {
        subject: "設定"
    },
    entry: {
        theme: {
            subject: "主題"
        },
        pass: {
            subject: "通行碼"
        },
        font: {
            subject: "字體大小"
        },
        locale: {
            subject: "語系"
        },
        favorite: {
            subject: "所有收藏文章留言通知"
        },
        bible: {
            subject: "聖經重新安裝",
            refresh: {
                subject: "聖經重新安裝",
                text: "即將重新安裝聖經\n請確認後執行"
            },
            version: {
                subject: "聖經重新安裝",
                text: "請先更新您的App版本\n是否前往應用商店更新"
            },
            success: {
                subject: "安裝完成"
            }
        },
        black: {
            subject: "黑名單管理"
        },
        slash: {
            subject: "封鎖名單"
        },
        storage: {
            subject: "儲存空間管理"
        },
        logout: {
            subject: "登出"
        }
    },
    handler: {
        logout: {
            subject: "即將登出",
            text: "請確認後執行"
        }
    },
    font: {
        nav: {
            subject: "字體大小"
        },
        subject: "拖曳底部文字大小設置的滑塊, 可以調整的字體大小",
        description: {
            subject: "設置成功後, 文章與聖經等頁面的字體大小均會發生改變",
            text: "某些頁面下, 字體過大可能會出現排版錯亂的現象, 如果使用過程中出現問題, 請反饋給開發團隊"
        }
    },
    pass: {
        enable: "開啟",
        disable: "關閉"
    },
    favorite: {
        nav: {
            subject: "收藏留言通知"
        },
        subject: "所有收藏文章留言通知",
        text: "若關閉該功能後, 未來不會再接收到任何收藏文章的留言通知",
        enable: "開啟",
        disable: "關閉",
        success: {
            subject: "更新成功"
        },
        failure: {
            subject: "更新失敗"
        }
    },
    black: {
        nav: {
            subject: "黑名單"
        },
        success: {
            subject: "解除成功"
        },
        failure: {
            subject: "解除失敗"
        },
        handler: {
            subject: "即將解除黑名單",
            text: "請確認後執行"
        }
    },
    slash: {
        nav: {
            subject: "封鎖名單"
        },
        success: {
            remove: {
                subject: "解除成功"
            },
            delete: {
                subject: "刪除成功"
            }
        },
        failure: {
            remove: {
                subject: "解除失敗"
            },
            delete: {
                subject: "刪除失敗"
            }
        },
        submit: {
            remove: "解除封鎖",
            delete: "刪除"
        },
        handler: {
            remove: {
                subject: "即將解除封鎖",
                text: "請確認後執行"
            },
            delete: {
                subject: "即將刪除",
                text: "請確認後執行"
            }
        }
    },
    theme: {
        nav: {
            subject: "切換主題"
        },
        name: {
            light: "淺色",
            dark: "深色",
            follow: "跟隨系統"
        },
        description: {
            subject: "自動跟隨系統主題設置",
            text: "與手機保持一致的淺色或深色模式"
        }
    }
}
