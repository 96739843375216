export default {
    edit: {
        signature: {
            nav: {
                subject: "签到内容"
            },
            tab: {
                0: {
                    subject: "基本"
                },
                1: {
                    subject: "支付"
                }
            },
            error: {
                subject: "找不到对应的报到资讯",
                text: {
                    subject: "可能您没有权限进行协助报到",
                    text: "请向教会管理人员联系或重新扫描二维码"
                }
            },
            order: {
                success: {
                    subject: "手动设置\n已支付完成",
                },
                failure: {
                    subject: "手动设置\n已支付失败",
                },
                status: {
                    change: {
                        subject: "手动设置已支付",
                        text: `即将跳过系统检查标示为已经支付\n请确认后执行`
                    }
                }
            },
            submit: {
                subject: "即将确认入场",
                text: "请确定相关资料无误后, 再进行入场确认",
                success: {
                    subject: "入场完成",
                },
                failure: {
                    subject: "入场失败\n请向管理人员确认",
                },
                signature: {
                    off: {
                        subject: "未完成支付"
                    },
                    used: {
                        subject: "已经入场"
                    },
                    subject: "确认入场"
                }
            }
        },
        nav: {
            subject: "活动内容"
        },
        help: {
            download: {
                subject: "避免活动现场网路不稳定, 建议您下载二维码到场使用"
            },
            used: {
                subject: "已经报到"
            },
            not: {
                paid: {
                    subject: "等待支付"
                }
            }
        },
        notice: {
            subject: "有部分报到等待支付",
            edit: {
                subject: "此报到目前正等待支付, 您可以先行下载",
            }
        },
        qrcode: {
            subject: "报到二维码",
            text: "第 %s 位报名人员报到二维码",
            preview: {
                show: {
                    subject: "显示"
                },
                download: {
                    subject: "下载"
                },
                resend: {
                    subject: "发送"
                }
            },
        },
        echelon: {
            subject: "场次"
        },
        order: {
            subject: "自行支付",
            newebpay: {
                subject: "线上支付",
            },
            field: {
                amount: "付款金额",
                no: "订单编号",
                submit: "付款时间",
                status: "订单状态"
            },
            status: {
                true: {
                    subject: "支付完成"
                },
                false: {
                    subject: "等待支付"
                }
            }
        },
        note: {
            subject: "活动须知",
            1: {
                subject: "二维码报到成功后将会失效, 请勿将报到二维码以任何形式转发给非报名以外人员"
            },
            2: {
                subject: "您所缴纳的活动费用将全数由『%s』收取, 爱无界协会不经手办理"
            },
            3: {
                subject: "活动如有疑问或其他问题, 请洽教会负责人员"
            },
            4: {
                subject: "活动由『%s』举办并保留活动的解释权利"
            },
            5: {
                subject: "线上支付服务由『蓝新金流平台』提供"
            }
        },
    },
    nav: {
        subject: "我的活动"
    },
    tab: {
        all: "全部",
    },
    payment: {
        subject: "自行支付",
        newebpay: {
            subject: "线上支付",
        }
    },
    qrcode: {
        subject: "扫码报到"
    },
    stamp: {
        subject: "报名时间"
    },
    status: {
        true: {
            subject: "完成报名"
        },
        false: {
            subject: "等待支付"
        }
    }
}
