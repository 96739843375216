export default {
    nav: {
        subject: "個人資料"
    },
    section: {
        information: "個人資訊",
        contact: "聯絡資訊",
        other: "其他資訊"
    },
    empty: {
        subject: "未填寫"
    },
    no: {
        nav: {
            subject: "會友編號"
        }
    },
    commitment: {
        nav: {
            subject: "委身編號"
        }
    },
    avatar: {
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    email: {
        nav: {
            subject: "電子郵件"
        }
    },
    passwd: {
        nav: {
            subject: "變更密碼"
        },
        notice: {
            bar: {
                success: "密碼一致",
                error: "密碼不一致",
                failure: "請輸入密碼"
            }
        },
        holder: {
            new: {
                subject: "請輸入新密碼"
            },
            confirm: {
                subject: "請再輸入一次密碼進行確認"
            },
        },
        handler: {
            submit: {
                subject: "即將變更密碼",
                text: "密碼變更完成後將會登出App\n請您使用新密碼登入"
            }
        },
        success: {
            subject: "密碼變更成功",
            text: "即將登出App\n請您使用新密碼登入"
        }
    },
    name: {
        nav: {
            subject: "姓名"
        },
        holder: {
            subject: "請輸入姓名"
        },
        error: {
            1001: {
                subject: "姓名至少需要2個字",
                text: ""
            }
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    title: {
        nav: {
            subject: "稱謂"
        },
        holder: {
            subject: "請選擇稱謂"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    birthday: {
        nav: {
            subject: "生日"
        },
        holder: {
            subject: "請輸入生日"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    phone: {
        nav: {
            subject: "聯絡電話"
        },
        holder: {
            subject: "請輸入聯絡電話"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    location: {
        nav: {
            subject: "所在區域"
        },
        holder: {
            subject: "請選擇所在區域"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    address: {
        nav: {
            subject: "地址"
        },
        holder: {
            subject: "請輸入地址"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    occupation: {
        nav: {
            subject: "職業"
        },
        holder: {
            subject: "請選擇職業"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    baptized: {
        nav: {
            subject: "受洗時間"
        },
        holder: {
            subject: "請選擇受洗時間"
        },
        success: {
            subject: "儲存成功"
        },
        failure: {
            subject: "儲存失敗"
        }
    },
    delete: {
        subject: "刪除帳號",
        handler: {
            subject: "刪除帳號",
            text: "是否確定刪除帳號\n刪除後將無法復原, 請謹慎確認後執行",
            confirm: {
                subject: "最後確認",
                text: "您真的要刪除帳號嗎"
            }
        },
        success: {
            subject: "帳號刪除完成",
            text: "期待您的再次使用"
        },
        failure: {
            subject: "刪除失敗"
        },
        submit: {
            submit: "殘忍刪除",
            cancel: "容我想想"
        }
    }
}
