export default {
    nav: {
        subject: "所屬教會"
    },
    handler: {
        submit: {
            subject: "即將前往關於我們網頁",
            text: "確定要前往嗎?"
        }
    }
}
