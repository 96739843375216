
export default {
    nav: {
        subject: "聚會紀錄"
    },
    field: {
        attend: {
            subject: "請選擇聚會"
        }
    },
    edit: {
        nav: {
            subject: "聚會內容"
        },
        notice: {
            subject: "聚會尚未開始"
        },
        not: {
            this: {
                week: "本週無聚會"
            }
        },
        field: {
            begin: {
                subject: "開始時間"
            },
            end: {
                subject: "結束時間"
            },
            location: {
                subject: "地點"
            },
            note: {
                subject: "備註"
            },
            detail: {
                subject: "詳細資訊",
                rollcall: {
                    subject: "點名單",
                    seq: {
                        subject: "序號"
                    },
                    uuid: {
                        subject: "識別碼"
                    },
                },
                team: {
                    subject: "族群",
                    seq: {
                        subject: "序號"
                    },
                    uuid: {
                        subject: "識別碼"
                    },
                    name: {
                        subject: "名稱"
                    }
                },
                attend: {
                    subject: "聚會",
                    seq: {
                        subject: "序號"
                    },
                    uuid: {
                        subject: "識別碼"
                    }
                },
                cycle: {
                    subject: "循環",
                    seq: {
                        subject: "序號"
                    }
                }
            }
        },
        text: {
            available: {
                subject: "應到會友"
            },
            multiple: {
                subject: "可複選"
            }
        },
        read: {
            subject: "發佈時間 %s"
        },
        rank: {
            subject: "自評",
            not: {
                yet: {
                    subject: "未自評"
                }
            }
        },
        note: {
            subject: "留言",
            not: {
                yet: {
                    subject: "未留言"
                }
            }
        },
        register: {
            subject: "簽到",
            not: {
                yet: {
                    subject: "未簽到"
                }
            }
        },
        submit: {
            not: {
                this: {
                    week: "本週無聚會"
                }
            }
        }
    }
}
