export default {
    nav: {
        subject: "聚會點名"
    },
    field: {
        attend: {
            subject: "請選擇聚會"
        }
    },
    edit: {
        nav: {
            subject: "點名內容"
        },
        notice: {
            subject: "聚會需要在開始之後才可以進行點名",
            end: {
                subject: "本場聚會已經結束"
            }
        },
        register: {
            subject: "聚會報到",
            feature: {
                register: "領袖簽到",
                qrcode: "顯示二維碼",
                scan: "掃描二維碼",
                url: "複製報到網址",
                detail: "聚會詳情",
                rebuild: "重製點名單"
            }
        },
        field: {
            begin: {
                subject: "開始時間"
            },
            end: {
                subject: "結束時間"
            },
            location: {
                subject: "地點"
            },
            note: {
                subject: "備註"
            },
            detail: {
                subject: "詳細資訊",
                rollcall: {
                    subject: "點名單",
                    seq: {
                        subject: "序號"
                    },
                    uuid: {
                        subject: "識別碼"
                    },
                },
                team: {
                    subject: "族群",
                    seq: {
                        subject: "序號"
                    },
                    uuid: {
                        subject: "識別碼"
                    },
                    name: {
                        subject: "名稱"
                    }
                },
                attend: {
                    subject: "聚會",
                    seq: {
                        subject: "序號"
                    },
                    uuid: {
                        subject: "識別碼"
                    }
                },
                cycle: {
                    subject: "循環",
                    seq: {
                        subject: "序號"
                    }
                }
            }
        },
        text: {
            available: {
                subject: "應到會友"
            },
            multiple: {
                subject: "可複選"
            }
        },
        read: {
            subject: "發佈時間 %s"
        },
        submit: {
            submit: "遞交點名",
            all: {
                on: "全數出席",
                off: "取消全數"
            },
            not: {
                this: {
                    week: "本週無聚會"
                }
            }
        },
        success: {
            submit: {
                subject: "遞交成功"
            },
            register: {
                subject: "遞交成功"
            },
            rebuild: {
                subject: "重製成功"
            }
        },
        failure: {
            submit: {
                subject: "遞交失敗"
            },
            register: {
                subject: "遞交失敗"
            },
            rebuild: {
                subject: "重製失敗"
            }
        },
        handler: {
            register: {
                subject: "領袖簽到",
                text: "即將為您遞交簽到, 請確認後執行"
            },
            qrcode: {
                subject: "顯示二維碼",
                text: "請出示本次聚會二維碼\n給會友掃描進行報到"
            },
            scan: {
                subject: "掃描二維碼",
                text: "請您進行掃描\n會友二維碼進行報到"
            },
            rebuild: {
                subject: "重製點名單",
                text: "若您發現點名單成員不正確\n可進行點名單重製, 請確認後執行",
                ended: {
                    subject: "重製點名單",
                    text: "本場聚會已經結束, 無法重製點名單"
                }
            },
            submit: {
                subject: "即將進行遞交點名",
                text: "請確認後執行"
            }
        }
    }
}
