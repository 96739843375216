import {RouteRecordRaw} from "vue-router";

const Profile: RouteRecordRaw[] = [{
    path: "my/easy/profile",
    name: "H5EasyProfile",
    component: () => import("@/page/h5/my/easy/profile/index.vue"),
}, {
    path: "my/easy/profile/name",
    name: "H5EasyProfileName",
    component: () => import("@/page/h5/my/easy/profile/name/index.vue"),
}, {
    path: "my/easy/profile/passwd",
    name: "H5EasyProfilePasswd",
    component: () => import("@/page/h5/my/easy/profile/passwd/index.vue"),
}, {
    path: "my/easy/profile/title",
    name: "H5EasyProfileTitle",
    component: () => import("@/page/h5/my/easy/profile/title/index.vue"),
}, {
    path: "my/easy/profile/birthday",
    name: "H5EasyProfileBirthday",
    component: () => import("@/page/h5/my/easy/profile/birthday/index.vue"),
}, {
    path: "my/easy/profile/phone",
    name: "H5EasyProfilePhone",
    component: () => import("@/page/h5/my/easy/profile/phone/index.vue"),
}, {
    path: "my/easy/profile/location",
    name: "H5EasyProfileLocation",
    component: () => import("@/page/h5/my/easy/profile/location/index.vue"),
}, {
    path: "my/easy/profile/address",
    name: "H5EasyProfileAddress",
    component: () => import("@/page/h5/my/easy/profile/address/index.vue"),
}, {
    path: "my/easy/profile/occupation",
    name: "H5EasyProfileOccupation",
    component: () => import("@/page/h5/my/easy/profile/occupation/index.vue"),
}, {
    path: "my/easy/profile/baptized",
    name: "H5EasyProfileBaptized",
    component: () => import("@/page/h5/my/easy/profile/baptized/index.vue"),
}]

export default Profile
