import {RouteRecordRaw} from "vue-router";

const Forgot: RouteRecordRaw[] = [{
    path: "forgot",
    name: "H5Forgot",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/forgot/index.vue"),
}]

export default Forgot
