export default {
    app: {
        line: "LINE",
        facebook: "facebook",
        messenger: "messenger",
        instagram: "Instagram",
        wechat: "WeChat",
        whats: "What's App"
    },
    other: {
        builtin: "更多分享"
    }
}
