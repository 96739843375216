export default {

    connect: {
        subject: "正在连接蓝新金流\n请耐心等候"
    },
    failure: {
        subject: "蓝新金流返回异常",
        text: "请重新提交活动<br/>或请稍后再试"
    }

}
