export default {
    nav: {
        subject: "聚会自评"
    },
    field: {
        attend: {
            subject: "请选择聚会"
        }
    },
    edit: {
        nav: {
            subject: "自评内容"
        },
        notice: {
            subject: "聚会需要在开始之后才可以进行评分"
        },
        field: {
            rank: "请为今天聚会的自己打个分数",
            note: "说些什么"
        },
        holder: {
            rank: "点击或拖曳星星进行打分数, 星星越多代表分数越高",
            note: "代祷、聚会心得、建议或其他"
        },
        success: {
            subject: "评分成功"
        },
        failure: {
            subject: "评分失败"
        },
        handler: {
            submit: {
                subject: "即将进行评分",
                text: "请确认后执行"
            }
        }
    }
}
