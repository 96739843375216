export default {
    coffee: {
        record: {
            edit: {
                nav: {
                    subject: "紀錄內容"
                },
                field: {
                    order: "我司單號",
                    no: "交易單號",
                    status: "支付狀態",
                    amount: "奉獻金額",
                    stamp: "建立時間",
                    trade: "交易時間"
                },
                status: {
                    waiting: "待支付",
                    success: "支付完成",
                    failure: "支付失敗",
                    cancel: "取消支付"
                }
            },
            tab: {
                all: "全部"
            },
            nav: {
                subject: "交易紀錄"
            },
            field: {
                order: "單號",
                amount: "金額",
                stamp: "時間"
            },
            status: {
                waiting: "待支付",
                success: "支付完成",
                failure: "支付失敗",
                cancel: "取消支付"
            }
        },
        buy: {
            connect: {
                subject: "正在連接藍新金流\n請耐心等候"
            },
            failure: {
                subject: "藍新金流返回異常",
                text: "請重新奉獻<br/>或請稍後再試"
            }
        },
        nav: {
            subject: "請我們喝咖啡"
        },
        handler: {
            submit: {
                subject: "感恩您支持我們",
                text: "這筆捐款將使用藍新金流\n送達開發者愛無界協會"
            }
        },
        failure: {
            subject: "結帳失敗"
        },
        submit: "前往結賬",
        cancel: "容我想想"
    },
    nav: {
        subject: "開發單位"
    },
    version: {
        subject: "目前版本"
    },
    status: {
        checking: "正在檢查版本",
        newest: "已經是最新版本",
        expire: {
            subject: "已經有更新版本",
            text: "點擊前往更新"
        }
    }
}
