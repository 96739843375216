/**
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 製造商
 */
export enum EnumSupplier {

    apple = "apple",

    android = "android"

}
