export default {
    coffee: {
        record: {
            edit: {
                nav: {
                    subject: "纪录内容"
                },
                field: {
                    order: "我司单号",
                    no: "交易单号",
                    status: "支付状态",
                    amount: "奉献金额",
                    stamp: "建立时间",
                    trade: "交易时间"
                },
                status: {
                    waiting: "待支付",
                    success: "支付完成",
                    failure: "支付失败",
                    cancel: "取消支付"
                }
            },
            tab: {
                all: "全部"
            },
            nav: {
                subject: "交易纪录"
            },
            field: {
                order: "单号",
                amount: "金额",
                stamp: "时间"
            },
            status: {
                waiting: "待支付",
                success: "支付完成",
                failure: "支付失败",
                cancel: "取消支付"
            }
        },
        buy: {
            connect: {
                subject: "正在连接蓝新金流\n请耐心等候"
            },
            failure: {
                subject: "蓝新金流返回异常",
                text: "请重新奉献<br/>或请稍后再试"
            }
        },
        nav: {
            subject: "请我们喝咖啡"
        },
        handler: {
            submit: {
                subject: "感恩您支持我们",
                text: "这笔捐款将使用蓝新金流\n送达开发者爱无界协会"
            }
        },
        failure: {
            subject: "结帐失败"
        },
        submit: "前往结账",
        cancel: "容我想想"
    },
    nav: {
        subject: "开发单位"
    },
    version: {
        subject: "目前版本"
    },
    status: {
        checking: "正在检查版本",
        newest: "已经是最新版本",
        expire: {
            subject: "已经有刷新版本",
            text: "点击前往刷新"
        }
    }
}
