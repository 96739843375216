export default {
    download: {
        image: {
            true: {
                subject: "图片下载成功"
            },
            false: {
                subject: "图片下载失败"
            }
        }
    },
    dplayer: {
        error: {
            unknown: "发生未知错误",
            live: {
                not: {
                    yet: "直播尚未开始"
                }
            },
            video: {
                private: "私人影片无法播放",
                not: {
                    yet: "影片尚未开始"
                }
            }
        },
        refresh: "重新读取"
    },
    loading: {
        subject: "正在读取中...请稍候",
        newebpay: {
            subject: "正在连接蓝新金流",
            text: "请稍候"
        }
    },
    audio: {
        error: {
            unknown: "发生未知错误",
        },
        refresh: "重新读取"
    },
    passwd: {
        strong: {
            subject: "密码强度未达标",
            text: {
                1: `密码需要 <b class="ft-16 text-danger">%d</b> 个字元以上`,
                2: `密码需要 <b class="ft-16 text-danger">%d</b> 个字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 个英文字与数字`,
                3: `密码需要 <b class="ft-16 text-danger">%d</b> 个字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 个大小写英文与数字`,
                4: `密码需要 <b class="ft-16 text-danger">%d</b> 个字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 个大小写英文, 数字与符号`,
                5: `密码需要 <b class="ft-16 text-danger">%d</b> 个字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 个大小写英文, 数字与符号\n且不得连续出现相同字元`
            }
        }
    },
    home: {
        search: {
            bible: {
                subject: `圣经搜索 - 结果共计 <b class="ft-15 text-success">%s</b> 笔`,
                empty: {
                    subject: "圣经搜索 - 找不到结果"
                }
            },
            empty: {
                subject: `搜索不到指定的文章`,
            }
        }
    },
    attend: {
        entry: {
            field: {
                begin: "开始时间",
                end: "结束时间"
            },
            status: {
                0: "未开始",
                1: "进行中",
                2: "已结束",
                3: "无聚会",
                rank: {
                    0: "未完成",
                    1: "已完成"
                }
            }
        }
    },
    article: {
        entry: {
            type: [{

            }, {
                subject: "图文",
            }, {
                subject: "影音",
            }, {
                subject: "MP3",
            },{
                subject: "直播",
            },{
                subject: "网页",
            }]
        }
    },
    register: {
        email: {
            subject: "使用电子邮件注册",
            holder: "请输入您的电子邮件",
            failure: {
                subject: "发生异常错误\n请稍后再试",
            }
        },
        invitation: {
            subject: "请输入您所在教会提供的邀请码",
            text: "共 %s 个字元",
            description: "点击小格子即可以开始输入",
            failure: {
                subject: "发生异常错误\n请稍后再试",
            }
        },
        verification: {
            subject: "已将验证信寄送至您的电子邮件",
            text: "请输入邮件中的验证码, 共 %s 位数字",
            description: "点击小格子即可以开始输入",
            failure: {
                subject: "发生异常错误\n请稍后再试",
            }
        },
        welcome: {
            subject: "欢迎使用我的教会App",
            text: "注册即将开始",
            submit: {
                subject: "开始注册"
            }
        },
        avatar: {
            subject: "设置头像",
            take: {
                1: "相册",
                2: "相机"
            }
        },
        name: {
            subject: "设置姓名",
            holder: "请输入您的姓名"
        },
        passwd: {
            subject: "设置登入密码",
            notice: {
                bar: {
                    success: "密码一致",
                    error: "密码不一致",
                    failure: "请输入密码"
                }
            },
            holder: {
                current: "请输入登入密码",
                confirm: "请再输入一次密码进行确认",
            }
        },
        church: {
            subject: "设置所在教会",
            text: "我没有所属教会",
            location: {
                subject: "所在区域",
                holder: {
                    subject: "请选择所在区域"
                }
            },
            entry: {
                subject: "所在教会",
                holder: {
                    subject: "请选择所在教会"
                },
                entry: {
                    subject: "选择所在教会",
                    country: {
                        subject: "所在区域",
                        holder: {
                            subject: "请选择所在区域"
                        }
                    },
                    city: {
                        subject: "所在城市",
                        holder: {
                            subject: "请选择所在城市"
                        }
                    },
                    keyword: {
                        subject: "关键字",
                        holder: {
                            subject: "输入关键字进行搜索"
                        }
                    },
                    available: {
                        subject: "教会名单",
                        holder: {
                            subject: "请选择您所在的教会"
                        }
                    }
                }
            }
        },
        personal: {
            subject: "设置个人资讯",
            title: {
                subject: "称谓",
                holder: {
                    subject: "请选择称谓"
                }
            },
            birthday: {
                subject: "生日",
                holder: {
                    subject: "请选择生日"
                }
            },
            phone: {
                subject: "电话",
                holder: {
                    subject: "请输入电话"
                }
            },
            location: {
                subject: "所在区域",
                holder: {
                    subject: "请选择所在区域"
                }
            },
            address: {
                subject: "地址",
                holder: {
                    subject: "请输入地址"
                }
            },
            occupation: {
                subject: "职业",
                holder: {
                    subject: "请选择职业"
                }
            },
            baptized: {
                subject: "受洗日期",
                holder: {
                    subject: "请选择受洗日期"
                }
            }
        },
        done: {
            subject: "太好了🎉🎉🎉",
            text: "即将完成注册",
            submit: {
                subject: "完成注册"
            },
            success: {
                subject: "注册成功",
            },
            failure: {
                subject: "注册失败\n请稍后再试",
            }
        }
    },
    forgot: {
        email: {
            subject: "别担心, 输入您的注册电子邮件",
            text: "我们将寄送验证码给您, 进行重设密码",
            holder: "请输入您的电子邮件",
            failure: {
                subject: "发生异常错误\n请稍后再试",
            }
        },
        verification: {
            subject: "已将验证信寄送至您的电子邮件",
            text: "请输入邮件中的验证码, 共 %s 位数字",
            description: "点击小格子即可以开始输入",
            failure: {
                subject: "发生异常错误\n请稍后再试",
            }
        },
        passwd: {
            subject: "重设登入密码",
            notice: {
                bar: {
                    success: "密码一致",
                    error: "密码不一致",
                    failure: "请输入密码"
                }
            },
            holder: {
                current: "请输入登入密码",
                confirm: "请再输入一次密码进行确认",
            }
        },
        done: {
            subject: "太好了🎉🎉🎉",
            text: "即将完成重设密码",
            submit: {
                subject: "确认重设"
            },
            success: {
                subject: "重设密码成功",
                text: "请您使用新密码登入"
            },
            failure: {
                subject: "重设密码失败\n请稍后再试",
            }
        }
    },
    font: {
        picker: {
            size: {
                1: "小",
                2: "中",
                3: "大",
                4: "特大",
            }
        }
    },
    take: {
        camera: {
            subject: "相机"
        },
        gallery: {
            subject: "相册"
        }
    },
    my: {
        easy: {
            leader: {
                shepherd: {
                    summary: {
                        team: {
                            subject: "族群"
                        },
                        attend: {
                            subject: "聚会"
                        },
                        partake: {
                            subject: "成员"
                        },
                        manager: {
                            subject: "托管"
                        }
                    },
                    edit: {
                        rollcall: {
                            field: {
                                begin: "开始时间",
                                end: "结束时间"
                            },
                            status: {
                                0: "未开始",
                                1: "进行中",
                                2: "已结束",
                                3: "无聚会",
                                rank: {
                                    0: "未完成",
                                    1: "已完成"
                                }
                            }
                        }
                    }
                }
            },
            setting: {
                black: {
                    remove: "解除"
                }
            }
        }
    },
    blank: {
        section: {
            subject: "暂时找不到您要的资料",
            text: "重新读取"
        }
    }
}
