import {RouteRecordRaw} from "vue-router";

import Activity from "@/router/modules/my/easy/activity";
import Profile from "@/router/modules/my/easy/profile";
import Setting from "@/router/modules/my/easy/setting";
import Donate from "@/router/modules/my/easy/donate";
import Leader from "@/router/modules/my/easy/leader";
import Developer from "@/router/modules/my/easy/developer";

const Easy: RouteRecordRaw[] = [
    ...Activity,
    ...Setting,
    ...Profile,
    ...Donate,
    ...Leader,
    ...Developer
].concat([{
    path: "my/easy/belong",
    name: "H5EasyBelong",
    component: () => import("@/page/h5/my/easy/belong/index.vue"),
}, {
    path: "my/easy/privacy",
    name: "H5EasyPrivacy",
    component: () => import("@/page/h5/my/easy/privacy/index.vue"),
}, {
    path: "my/easy/feedback",
    name: "H5EasyFeedback",
    component: () => import("@/page/h5/my/easy/feedback/index.vue"),
}, {
    path: "my/easy/setting",
    name: "H5EasySetting",
    component: () => import("@/page/h5/my/easy/setting/index.vue"),
}])

export default Easy
