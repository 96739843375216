import rollcall from "@/language/modules/page/my/easy/leader/shepherd/rollcall/zhTW"

export default {
    nav: {
        subject: "牧養族群"
    },
    edit: {
        nav: {
            subject: "所屬族群"
        },
        rollcall: rollcall
    }
}
