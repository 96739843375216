import i18n from "@/language";
import {computed} from "vue";
import {SettingStore} from "@/stores/modules/setting";
import {EnumSupplier} from "@/enum/supplier";
import {EnumFontSize} from "@/enum/font";
import {useRoute} from "vue-router";
import {SettingState} from "@/stores/interface/setting";

export const useSetting = () => {

    enum H5Key {

        /**
         * JS - 交互 - 權杖
         */
        h5Token = "tokenKey",

        /**
         * JS - 交互 - 機構
         */
        h5Organization = "organizationKey",

        /**
         * JS - 交互 - 語系
         */
        h5Region = "regionKey",

        /**
         * JS - 交互 - 主題
         */
        h5Theme = "themeKey",

        /**
         * JS - 交互 - 主題 - 跟隨
         */
        h5ThemeFollow = "themeFollowKey",

        /**
         * JS - 交互 - 主題 - 系統
         */
        h5ThemeSystem = "themeSystemKey",

        /**
         * JS - 交互 - 字級
         */
        h5FontSize = "fontSizeKey",

        /**
         * JS - 交互 - 目前手機時區偏移量
         */
        h5SecondFromGMT = "secondFromGMTKey",

        /**
         * JS - 交互 - 製造商
         */
        h5Supplier = "supplierKey",

        /**
         * JS - 交互 - 通行碼
         */
        h5PassCode = "passCodeKey",

        /**
         * JS - 交互 - 文章 - 收藏 - 留言
         */
        h5ArticleComment = "articleComment",

        /**
         * JS - 交互 - 版本號
         */
        h5Version = "versionKey",

        /**
         * JS - 交互 - app - bundle name
         */
        h5BundleName = "bundleNameKey"

    }

    interface RegionRaw {
        id: string
        key: string,
        value: string
    }

    /**
     * 可用語言
     */
    const availableRegion: RegionRaw[] = [{
        id: "zhTW",
        key: "zh-TW",
        value: "繁體中文",
    }, {
        id: "zhCN",
        key: "zh-CN",
        value: "简体中文"
    }]

    /**
     * 用戶 store
     */
    const settingStore = SettingStore()

    /**
     * 透析 - 參數
     */
    const onExploit = () => {

        const route = useRoute()

        //權杖
        const token: string | null = route.query[H5Key.h5Token] as string

        //時區
        const region: string | null = route.query[H5Key.h5Region] as string

        //主題 - 深色
        const theme: boolean = route.query[H5Key.h5Theme] === "true"

        //主題 - 跟隨
        const themeFollow: boolean = route.query[H5Key.h5ThemeFollow] === "true"

        //主題 - 系統
        const themeSystem: boolean = route.query[H5Key.h5ThemeSystem] === "true"

        //通行碼
        const passCode: boolean = route.query[H5Key.h5PassCode] === "true"

        //文章 - 收藏 - 留言
        const articleComment: boolean = route.query[H5Key.h5ArticleComment] === "true"

        //製造商
        const supplier: string | null = route.query[H5Key.h5Supplier] as string

        //版本號
        const version: string | null = route.query[H5Key.h5Version] as string

        //App - bundle - name
        const bundle: string | null = route.query[H5Key.h5BundleName] as string

        //字級
        const fontSizeCheck: number = parseInt(route.query[H5Key.h5FontSize] as string)
        const fontSize: EnumFontSize = isNaN(fontSizeCheck) ? EnumFontSize.middle : fontSizeCheck as EnumFontSize

        //時區 - 偏移 (秒)
        const secondFromGMTCheck: number = parseInt(route.query[H5Key.h5SecondFromGMT] as string)
        const secondFromGMT: number = isNaN(secondFromGMTCheck) ? 28800 : secondFromGMTCheck

        //機構
        const organizationCheck: number = parseInt(route.query[H5Key.h5Organization] as string)
        const organization: number = isNaN(organizationCheck) ? 1 : organizationCheck

        //設置 - 權杖
        if (token) setToken(token)
        //設置 - 預設 - 權杖
        else setToken(null)

        //設置 - 時區
        if (region) setRegion(region)
        //設置 - 預設 - 時區
        else setRegion("zhTW")

        //設置 - 製造商
        if (supplier) setSupplier(supplier)
        //設置 - 預設 - 製造商 (android)
        else setSupplier("android")

        //設置 - 版本號
        if (version) setVersion(version)
        //設置 - 預設 - 版本號
        else setVersion("1.0.0.001")

        //設置 - App - bundle - name
        if (bundle) setBundle(bundle)
        //設置 - 預設 - App - bundle - name (iChurch.Android)
        else setBundle("com.justforthee.ichurch")

        //設置 - 主題 - 深色
        setDarkTheme(theme)

        //設置 - 主題 - 跟隨
        setFollowTheme(themeFollow)

        //設置 - 主題 - 系統
        setSystemTheme(themeSystem)

        //設置 - 通行碼
        setPassCode(passCode)

        //文章 - 收藏 - 留言 - 通知
        setArticleComment(articleComment)

        //設置 - 字級
        setFontSize(fontSize)

        //設置 - 時區 - 偏移 (秒)
        setSecondFromGMT(secondFromGMT)

        //設置 - 機構
        setOrganization(organization)

    }

    /**
     * 設置 - 權杖
     */
    const setToken = (e: string | null) => {
        settingStore.setToken(e)
    }

    /**
     * 返回 - 權杖
     */
    const getToken = computed((): string | null => {
        return settingStore.getToken
    })

    /**
     * 設置 - 檔頭 - 語系
     */
    const setRegion = (e: string) => {

        //確認語系存在
        const r: RegionRaw | undefined = availableRegion.find((i: RegionRaw) => i.key === e)

        //如果找不到正確語系, 不往下處理
        if (r === undefined) return false

        //登記到store
        settingStore.setRegion(e)

        //修改App的原始属性
        i18n.global.locale.value = r.id

        //修改頁面屬性
        document.querySelector('html')!.setAttribute('lang', e);

    }

    /**
     * 設置 - 機構
     */
    const setOrganization = (e: number) => {
        settingStore.setOrganization(e)
    }

    /**
     * 返回 - 機構
     */
    const getOrganization = computed((): number => {
        return settingStore.getOrganization
    })

    /**
     * 返回 - 檔頭 - 語系
     */
    const getRegion = computed((): string => {
        return settingStore.getRegion
    })

    /**
     * 設置 - 主題
     */
    const setDarkTheme = (e: any) => {
        settingStore.setTheme(e)
        const body = document.getElementsByTagName("BODY")[0];
        //切換的時候, 加入一個暫時關閉 .form-control transition的功能
        //不然切換的時候會閃一下很醜
        body.classList.add("pv")
        isDarkTheme.value ? body.classList.add("van-theme-dark") : body.classList.remove("van-theme-dark")
        setTimeout(()=>{
            //完成後在去掉暫時transition的功能
            body.classList.remove("pv")
        }, 50)
    }

    /**
     * 設置 - 主題 - 跟隨
     */
    const setFollowTheme = (e: any) => {
        settingStore.setFollowTheme(e)
    }

    /**
     * 設置 - 主題 - 系統
     */
    const setSystemTheme = (e: any) => {
        settingStore.setSystemTheme(e)
    }

    /**
     * 設置 - 通行碼
     */
    const setPassCode = (e: any) => {
        settingStore.setPassCode(e)
    }

    /**
     * 返回 - 主題
     */
    const isDarkTheme = computed((): boolean => {
        return settingStore.isDarkTheme
    })

    /**
     * 返回 - 主題 - 跟隨
     */
    const isFollowTheme = computed((): boolean => {
        return settingStore.isFollowTheme
    })

    /**
     * 返回 - 主題 - 系統
     */
    const isSystemDarkTheme = computed((): boolean => {
        return settingStore.isSystemDarkTheme
    })

    /**
     * 返回 - 通行碼
     */
    const isPassCode = computed((): boolean => {
        return settingStore.isPassCode
    })

    /**
     * 返回 - 檔頭 - 語系
     */
    const setSecondFromGMT = (e: number) => {
        settingStore.setSecondFromGMT(e)
    }

    /**
     * 返回 - 檔頭 - 語系
     */
    const getSecondFromGMT = computed((): number => {
        return settingStore.getSecondFromGMT
    })

    /**
     * 設置 - 製造商
     */
    const setSupplier = (e: string | null) => {
        settingStore.setSupplier(e)
    }

    /**
     * 返回 - 製造商
     */
    const getSupplier = computed((): string | null => {
        return settingStore.getSupplier
    })

    /**
     * 返回 - 製造商 - Apple
     */
    const isSupplierApple = computed((): boolean => {
        return settingStore.getSupplier === EnumSupplier.apple
    })

    /**
     * 返回 - 製造商 - Android
     */
    const isSupplierAndroid = computed((): boolean => {
        return settingStore.getSupplier === EnumSupplier.android
    })

    /**
     * 設置 - 字型
     */
    const setFontSize = (e: EnumFontSize) => {
        settingStore.setFontSize(e)
    }

    /**
     * 返回 - 字型
     */
    const getFontSize = computed((): EnumFontSize =>{
        return settingStore.getFontSize
    })

    /**
     * 設置 - 所有收藏文章留言通知
     */
    const setArticleComment = (e: boolean) => {
        settingStore.setArticleComment(e)
    }

    /**
     * 返回 - 所有收藏文章留言通知
     */
    const isArticleComment = computed((): boolean =>{
        return settingStore.isArticleComment
    })

    /**
     * 設置 - 版本
     */
    const setVersion = (e: string) => {
        settingStore.setVersion(e)
    }

    /**
     * 返回 - 版本
     */
    const getVersion = computed((): string | null => {
        return settingStore.getVersion
    })

    /**
     * 返回 - 版本 - 建置號
     */
    const getVersionBuild = computed((): number => {
        const e: string[] = (settingStore.getVersion as string).split(".")
        return parseInt(e[e.length - 1])
    })

    /**
     * 設置 - App - bundle - name
     */
    const setBundle = (e: string) => {
        settingStore.setBundle(e)
    }

    /**
     * 返回 - App - bundle - name
     */
    const getBundle = computed((): string | null => {
        return settingStore.getBundle
    })

    /**
     * 開發區直接設定 active.is.initial = true
     */
    const onDeveloper = (e: {
       initial: boolean
    }) => { if (["https:", "http:"].includes(window.location.protocol)) e.initial = true }

    //❗️❗️ 危險 ❗️❗️
    //@ts-ignore
    //交互 - 呼叫 - 補完 - setting - localdb 的值
    window.bridgeMakeUpSetting = (e: any) => {

        //從localdb重新注入, 讓畫面重新反值
        const currentValue: SettingState = JSON.parse(localStorage.SettingStore) as SettingState

        //語系
        setRegion(currentValue.region)

        //字級
        setFontSize(currentValue.fontSize)

        //深色主題
        setDarkTheme(currentValue.theme)

        //跟隨
        setFollowTheme(currentValue.follow)

        //收藏文章留言通知
        setArticleComment(currentValue.articleComment)

        //❗️❗️ 危險 ❗️❗️
        //補回 - 非H5App - 操作 - 沙箱值
        for (const [key, value] of Object.entries(JSON.parse(e))) {
            if (key === "themeKey") setDarkTheme(value === "true")
            else if (key === "themeFollowKey") setFollowTheme(value === "true")
            else if (key === "regionKey") setRegion(value as string)
            else if (key === "passCodeKey") setPassCode(value === "true")
            else if (key === "fontSizeKey") setFontSize((isNaN(parseInt(value as string)) ? EnumFontSize.middle : parseInt(value as string) ) as EnumFontSize)
        }

    }

    return {
        onExploit,
        setOrganization,
        getOrganization,
        setRegion,
        getRegion,
        setToken,
        getToken,
        setDarkTheme,
        isDarkTheme,
        setFollowTheme,
        isFollowTheme,
        setSystemTheme,
        isSystemDarkTheme,
        getSupplier,
        setSupplier,
        isSupplierApple,
        isSupplierAndroid,
        setSecondFromGMT,
        getSecondFromGMT,
        setFontSize,
        getFontSize,
        setArticleComment,
        isArticleComment,
        setPassCode,
        isPassCode,
        setVersion,
        getVersion,
        getVersionBuild,
        setBundle,
        getBundle,
        onDeveloper
    }

}
