import {RouteRecordRaw} from "vue-router";

const Article: RouteRecordRaw[] = [{
    path: "article/my/:tab?",
    name: "H5ArticleMy",
    component: () => import("@/page/h5/article/my/index.vue"),
}, {
    path: "article/soul/:tab?",
    name: "H5ArticleSoul",
    component: () => import("@/page/h5/article/soul/index.vue"),
}, {
    path: "article/daily",
    name: "H5ArticleDaily",
    component: () => import("@/page/h5/article/daily/index.vue"),
}, {
    path: "article/edit/:uuid",
    name: "H5ArticleEdit",
    component: () => import("@/page/h5/article/edit/index.vue"),
}, {
    path: "article/edit/:uuid/alarm",
    name: "H5ArticleEditAlarm",
    component: () => import("@/page/h5/article/edit/alarm/index.vue"),
}, {
    path: "article/edit/:uuid/activity/buy/:order",
    name: "H5ArticleEditActivityBuy",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/article/edit/activity/buy/index.vue"),
}, {
    path: "article/edit/:uuid/activity/completion/:order",
    name: "H5ArticleEditActivityCompletion",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/article/edit/activity/completion/index.vue"),
}, {
    path: "article/edit/activity/signature/:uuid",
    name: "H5ArticleEditActivitySignature",
    component: () => import("@/page/h5/article/edit/activity/signature/index.vue"),
}]

export default Article
