export default {
    record: {
        edit: {
            nav: {
                subject: "纪录内容"
            },
            field: {
                order: "我司单号",
                no: "交易单号",
                status: "支付状态",
                amount: "奉献金额",
                stamp: "建立时间",
                trade: "交易时间"
            },
            status: {
                waiting: "待支付",
                success: "支付完成",
                failure: "支付失败",
                cancel: "取消支付"
            }
        },
        tab: {
            all: "全部",
        },
        nav: {
            subject: "奉献纪录"
        },
        field: {
            order: "单号",
            amount: "金额",
            stamp: "时间"
        },
        status: {
            waiting: "待支付",
            success: "支付完成",
            failure: "支付失败",
            cancel: "取消支付"
        }
    },
    buy: {
        connect: {
            subject: "正在连接蓝新金流\n请耐心等候"
        },
        failure: {
            subject: "蓝新金流返回异常",
            text: "请重新奉献<br/>或请稍后再试"
        }
    },
    nav: {
        subject: "奉献"
    },
    subject: "我想奉献",
    holder: {
        subject: "请输入奉献金额"
    },
    handler: {
        submit: {
            newebpay: {
                subject: "即将前往蓝新金流进行奉献",
                text: "确定要前往吗?"
            },
            hyperlink: {
                subject: "即将前往外部奉献连结",
                text: "确定要前往吗?"
            }
        }
    },
    description: {
        subject: "注意事项",
        other: {
            1: "使用奉献服务建议先前往 ＞ 会员中心 ＞ 个资编辑, 填写联络方式方便教会开立收据",
            2: "你所奉献的金额全数奉献给「%s」, 爱无界协会不经手办理",
            3: "奉献如有疑问或需奉献征信, 请洽教会负责人员",
            4: "线上奉献交易服务由「蓝新金流平台」提供"
        },
        101: {
            1: "奉献资讯将定期公布于教会办公室公告栏及主日周报",
            2: "各类奉献，请参阅奉献资讯页面相关内容",
            3: "<ul><li>线上奉献<li><li>定期定额汇款<li><li>国内单笔现金／支票<li><li>国外汇款奉献<li></ul>",
            4: "请尽量使用定期定额银行转帐奉献方式，以减少纸张及交易手续等成本",
            5: "",
            6: "若有任何问题请洽教会财务部同工",
            7: "洪小姐 (02-8101-2100 ext. 116)",
            8: "财务部专用电子邮件信箱",
            9: "accountant{'@'}iwillshare.org",
            10: "地址：台北市110 信义区信义路五段7号52楼C室"
        }
    },
    failure: {
        subject: "奉献失败"
    },
    submit: "前往奉献"
}
