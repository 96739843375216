import {RouteRecordRaw} from "vue-router";

const Developer: RouteRecordRaw[] = [{
    path: "my/easy/developer",
    name: "H5EasyDeveloper",
    component: () => import("@/page/h5/my/easy/developer/index.vue"),
}, {
    path: "my/easy/developer/coffee",
    name: "H5EasyDeveloperCoffee",
    component: () => import("@/page/h5/my/easy/developer/coffee/index.vue"),
}, {
    path: "my/easy/developer/coffee/buy/:uuid",
    name: "H5EasyDeveloperCoffeeBuy",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/my/easy/developer/coffee/buy/index.vue"),
}, {
    path: "my/easy/developer/coffee/completion/:uuid",
    name: "H5EasyDeveloperCoffeeCompletion",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/my/easy/developer/coffee/completion/index.vue"),
}, {
    path: "my/easy/developer/coffee/record/:tab?",
    name: "H5EasyDeveloperCoffeeRecord",
    component: () => import("@/page/h5/my/easy/developer/coffee/record/index.vue"),
}, {
    path: "my/easy/developer/coffee/record/edit/:uuid",
    name: "H5EasyDeveloperCoffeeRecordEdit",
    component: () => import("@/page/h5/my/easy/developer/coffee/record/edit/index.vue"),
}]

export default Developer
