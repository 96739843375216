import refresh from "./refresh/zhTW"
import page from "./page/zhTW"
import submit from "./submit/zhTW"
import util from "./util/zhTW"
import share from "./share/zhTW"
import component from "./component/zhTW"

export default {

    /**
     * 刷新器
     */
    refresh: refresh,

    /**
     * 頁面
     */
    page: {
        /**
         * 安裝畫面
         */
        launch: {
            subject: "請安裝App用戶端"
        },
        ...page
    },

    /**
     * 提交
     */
    submit: submit,

    /**
     * 工具
     */
    util: util,

    /**
     * 分享 - App
     */
    share: share,

    /**
     * 元件
     */
    component: component

}
