import activity from "@/language/modules/page/article/activity/zhCN"

export default {

    entry: {
        tab: {
            all: "全部",
        },
        my: {
            nav: "我的教会"
        },
        soul: {
            nav: "心灵捕手"
        },
        daily: {
            nav: "每日灵粮"
        }
    },
    edit: {

        activity: activity,

        alarm: {
            nav: {
                subject: "检举"
            },
            subject: {
                reason: "理由",
                feedback: "意见或建议"
            },
            holder: {
                feedback: "请输入您的意见或建议(可留空)"
            },
            success: {
                subject: "检举成功"
            },
            failure: {
                subject: "检举失败"
            },
            reason: [
                "滥发广告",
                "骚扰行为",
                "其他"
            ]
        },
        holder:{
            comment: {
                subject: "说点什么吗"
            }
        },
        summary: {
            comment: {
                subject: "留言"
            },
            favorite: {
                subject: "收藏"
            },
            share: {
                subject: "分享"
            }
        },
        favorite: {
            put: {
                success: {
                    subject: "收藏成功"
                },
                failure: {
                    subject: "收藏失败"
                }
            },
            delete: {
                success: {
                    subject: "回收成功"
                },
                failure: {
                    subject: "回收失败"
                }
            }
        },
        comment: {
            empty: {
                subject: "还没有任何留言"
            },
            more: {
                alarm: {
                    subject: "检举",
                    success: {
                        subject: "检举成功",
                        text: "我们已经收到您宝贵的意见并尽快审视\n感谢您宝贵的意见"
                    },
                    failure: {
                        subject: "检举失败"
                    }
                },
                black: {
                    list: {
                        subject: "黑名单",
                        success: {
                            subject: "加入成功",
                            text: "您可以前往黑名单管理中\n解除这名会友的黑名单"
                        },
                        failure: {
                            subject: "加入失败"
                        }
                    }
                },
                handler: {
                    alarm: {
                        subject: "即将检举这名会友",
                        text: "请确认后执行"
                    },
                    black: {
                        list: {
                            subject: "即将将这名会友加入黑名单",
                            text: "请确认后执行"
                        }
                    }
                }
            },
            success: {
                subject: "留言成功"
            },
            failure: {
                subject: "留言失败"
            },
            like: {
                put: {
                    success: {
                        subject: "感谢您的点赞"
                    },
                    failure: {
                        subject: "点赞失败"
                    }
                },
                delete: {
                    success: {
                        subject: "回收成功"
                    },
                    failure: {
                        subject: "回收失败"
                    }
                }
            }
        },
        share: {
            failure: {
                subject: `糟糕\n发生错误`
            }
        },
        read: {
            subject: "发布于 %s ・ 共计 %s 人观看"
        },
        proceed: {
            live: {
                subject: "前往"
            },
            activity: {
                subject: "提交"
            }
        },
        more: {
            share: {
                subject: "分享"
            },
            favorite: {
                subject: {
                    enable: "收藏文章",
                    disable: "取消收藏"
                },
                update: {
                    enable: "收藏成功",
                    disable: "取消成功"
                }
            },
            theme: {
                subject: "切换主题"
            },
            font: {
                subject: "字体大小"
            },
            alarm: {
                subject: "检举"
            }
        }
    }
}
