export default {

    connect: {
        subject: "正在連接藍新金流\n請耐心等候"
    },
    failure: {
        subject: "藍新金流返回異常",
        text: "請重新提交活動<br/>或請稍後再試"
    }

}
