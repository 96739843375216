import {EnumFontSize} from "@/enum/font";
import {EnumTheme} from "@/enum/theme";
import {EnumShareTypeApp, EnumShareTypeOther} from "@/enum/share";

export const useJsBridge = () => {

    //是否為正式區
    const isProd: boolean = import.meta.env.PROD as boolean

    //js - 交互 - 傳輸 - 名稱
    const jsBridgeKey: string = "H5UrlFragment"

    enum Type {
        //錯誤頁面
        onError404 = "onError404",
        //交互 - 接管 (Android Only)
        onTakeOver = "onTakeOver",
        //讀取  - 開始
        onStarted = "onStarted",
        //讀取  - 完成
        onFinished = "onFinished",
        //讀取  - 完成 - 渲染
        onNextTick = "onNextTick",
        //讀取  - 錯誤
        onReceivedError = "onReceivedError",
        //讀取  - 未授權
        onUnauthorized = "onUnauthorized",
        //請求 - 旋轉
        onRequestedOrientation = "onRequestedOrientation",
        //旋轉 - 直屏
        onRotatePortrait = "onRotatePortrait",
        //旋轉 - 橫屏
        onRotateLandscape = "onRotateLandscape",
        //下載 - 圖片
        onImageDownload = "onImageDownload",
        //前往 - 網址 (core_admin_article.type = 5)
        onHyperlink = "onHyperlink",
        //前往 - 直撥 (整合)
        onLive = "onLive",
        //分享
        onShare = "onShare",
        //收藏
        onFavoriteSave = "onFavoriteSave",
        //收藏 - 回收
        onFavoriteRecycle = "onFavoriteRecycle",
        //聖經重裝
        onBibleUpgrade = "onBibleUpgrade",
        //主題 - 淺
        onThemeLight = "onThemeLight",
        //主題 - 深
        onThemeDark = "onThemeDark",
        //主題 - 跟隨系統 - 開
        onThemeFollowOn = "onThemeFollowOn",
        //主題 - 跟隨系統 - 關
        onThemeFollowOff = "onThemeFollowOff",
        //字級 - 小
        onFontSmall = "onFontSmall",
        //字級 - 中
        onFontMiddle = "onFontMiddle",
        //字級 - 大
        onFontLarge = "onFontLarge",
        //字級 - 特大
        onFontExtra = "onFontExtra",
        //活動 - 報名 - 支付
        onActivityBuy = "onActivityBuy",
        //留言
        onComment = "onComment",
        //留言 - 完成
        onCommentComplete = "onCommentComplete",
        //修改背景色
        onBackground = "onBackground",
        //產生導航欄
        onNavigation = "onNavigation",
        //修改導航欄背景色
        onNavigationBackground = "onNavigationBackground",
        //隱藏奉獻紀錄
        onNavigationDonateRecordHide = "onNavigationDonateRecordHide",
        //啟動通行碼
        onPassCode = "onPassCode",
        //切換語系
        onLocale = "onLocale",
        //儲存空間管理
        onStorage = "onStorage",
        //掃描
        onScan = "onScan",
        //相機
        onCamera = "onCamera",
        //相冊
        onAlbum = "onAlbum",
        //變更密碼
        onPasswd = "onPasswd",
        //前往教會關於
        onChurchHyperlink = "onChurchHyperlink",
        //跳轉奉獻頁面 (藍新)
        onDonate = "onDonate",
        //跳轉請我們喝咖啡頁面 (藍新)
        onCoffee = "onCoffee",
        //開發團隊 - 跳轉
        onDevelopOpen = "onDevelopOpen",
        //開發團隊 - 請我們喝咖啡
        onDeveloperCoffee = "onDeveloperCoffee",
        //開啟Store
        onStoreOpen = "onStoreOpen",
        //搜尋 - 首頁 - 聖經
        onSearchHomeBible = "onSearchHomeBible",
        //教學 - 完成 - 提交
        onTeachSubmit = "onTeachSubmit",
        //註冊 - 捲軸 - 上鎖
        onRegisterScrollLock = "onRegisterScrollLock",
        //註冊 - 捲軸 - 解鎖
        onRegisterScrollUnLock = "onRegisterScrollUnLock",
        //註冊 - 返回 - 上鎖
        onRegisterBackLock = "onRegisterBackLock",
        //註冊 - 返回 - 解鎖
        onRegisterBackUnLock = "onRegisterBackUnLock",
        //註冊 - 放棄
        onRegisterDismiss = "onRegisterDismiss",
        //註冊 - 成功
        onRegisterSuccess = "onRegisterSuccess",
        //忘記密碼 - 成功
        onForgotSuccess = "onForgotSuccess",
        //掃碼成功
        onFactorySuccess = "onFactorySuccess",
        //我的 - 個人資料 - 更換 - 名稱
        onMyProfileName = "onMyProfileName",
        //我的 - 個人資料 - 更換 - 頭像
        onMyProfileAvatar = "onMyProfileAvatar",
        //我的 - 實用工具 - 設置 - 文章 - 收藏 - 通知
        onMyEasySettingArticleComment = "onMyEasySettingArticleComment",
        //我的 - 實用工具 - 設置 - 封鎖名單 - 解除
        onMyEasySettingSlashRemove = "onMyEasySettingSlashRemove",
        //我的 - 實用工具 - 設置 - 封鎖名單 - 刪除
        onMyEasySettingSlashDelete = "onMyEasySettingSlashDelete",
        //聚會 - 自評 - 完成
        onAttendRankComplete = "onAttendRankComplete",
        //聚會 - 點名單 - 內容 - 掃描
        onAttendRollCallEditScan = "onAttendRollCallEditScan",
        //登出
        onLogout = "onLogout",
        //刪除帳號
        onDeleteAccount = "onDeleteAccount",
        //設置 - 頭像
        onAvatar = "onAvatar",
        //返回
        onBackPress = "onBackPress"
    }

    /**
     * 錯誤404
     */
    const onError404 = () => build(Type.onError404)

    /**
     * 交互接管 (Android Only)
     */
    const onTakeOver = () => build(Type.onTakeOver)

    /**
     * 讀取 - 開始
     */
    const onStarted = () => build(Type.onStarted)

    /**
     * 讀取  - 完成
     */
    const onFinished = () => build(Type.onFinished)

    /**
     * 讀取  - 完成 - 渲染
     */
    const onNextTick = () => build(Type.onNextTick)

    /**
     * 讀取 - 失敗
     */
    const onReceivedError = () => build(Type.onReceivedError)

    /**
     * 讀取 - 未授權
     */
    const onUnauthorized = () => build(Type.onUnauthorized)

    /**
     * 請求 - 旋轉
     */
    const onRequestedOrientation = () => build(Type.onRequestedOrientation)

    /**
     * 旋轉 - 直屏
     */
    const onRotatePortrait = () => build(Type.onRotatePortrait)

    /**
     * 旋轉 - 橫屏
     */
    const onRotateLandscape = () => build(Type.onRotateLandscape)

    /**
     * 下載圖片
     */
    const onImageDownload = (e: string | null) => build(
        Type.onImageDownload,
        e
    )

    /**
     * 前往 - 網址 (core_admin_article.type = 5)
     */
    const onHyperlink = (e: {
        uuid: string | null,
        url: string | null
    }) => build(
        Type.onHyperlink,
        JSON.stringify(e)
    )

    /**
     * 前往 - 直撥 (整合)
     */
    const onLive = (e: string | null) => build(
        Type.onLive,
        e
    )

    /**
     * 分享
     */
    const onShare = async (
        e: EnumShareTypeApp | EnumShareTypeOther,
        data: string
    ) => build(Type.onShare, data)

    /**
     * 收藏
     */
    const onFavoriteSave = (e: {
        uuid: string
        avatarUrl: string | null
        subject: string
        text: string
        remind: boolean
        stamp: number
    }) => build(
        Type.onFavoriteSave,
        JSON.stringify(e)
    )

    /**
     * 收藏 - 回收
     */
    const onFavoriteRecycle = (uuid: string) => build(
        Type.onFavoriteRecycle,
        uuid
    )

    /**
     * 聖經重裝
     */
    const onBibleUpgrade = () => build(Type.onBibleUpgrade)

    /**
     * 主題
     */
    const onTheme = (e: EnumTheme) => {
        switch (e) {
            case EnumTheme.light:
                build(Type.onThemeLight)
                break
            case EnumTheme.dark:
                build(Type.onThemeDark)
                break
            case EnumTheme.followOn:
                build(Type.onThemeFollowOn)
                break
            case EnumTheme.followOff:
                build(Type.onThemeFollowOff)
                break
        }
    }

    /**
     * 字級
     */
    const onFont = (e: EnumFontSize) => {
        switch (e) {
            case EnumFontSize.small:
                build(Type.onFontSmall)
                break
            case EnumFontSize.middle:
                build(Type.onFontMiddle)
                break
            case EnumFontSize.large:
                build(Type.onFontLarge)
                break
            case EnumFontSize.extra:
                build(Type.onFontExtra)
                break
        }
    }

    /**
     * 活動 - 報名 - 支付
     */
    const onActivityBuy = (e: {
        uuid: string,
        order: string
    }) => build(
        Type.onActivityBuy,
        JSON.stringify(e)
    )

    /**
     * 留言
     */
    const onComment = () => build(Type.onComment)

    /**
     * 留言 - 完成
     */
    const onCommentComplete = (e: string) => build(Type.onCommentComplete, e)

    /**
     * 修改背景色
     */
    const onBackground = (e: string) => build(Type.onBackground, e)

    /**
     * 產生普通導航欄
     */
    const onNavigation = (e: string) => build(Type.onNavigation, e)

    /**
     * 修改導航欄背景色
     */
    const onNavigationBackground = (e: string) => build(Type.onNavigationBackground, e)

    /**
     * 隱藏奉獻紀錄
     */
    const onNavigationDonateRecordHide = () => build(Type.onNavigationDonateRecordHide)

    /**
     * 啟動通行碼
     */
    const onPasscode = () => build(Type.onPassCode)

    /**
     * 切換語系
     */
    const onLocale = () => build(Type.onLocale)

    /**
     * 儲存空間管理
     */
    const onStorage = () => build(Type.onStorage)

    /**
     * 掃描
     */
    const onScan = () => build(Type.onScan)

    /**
     * 相機
     */
    const onCamera = () => build(Type.onCamera)

    /**
     * 相冊
     */
    const onAlbum = () => build(Type.onAlbum)

    /**
     * 變更密碼
     */
    const onPasswd = () => build(Type.onPasswd)

    /**
     * 前往教會關於
     */
    const onChurchHyperlink = (e: any) => build(Type.onChurchHyperlink, e)

    /**
     * 跳轉奉獻頁面 (藍新)
     */
    const onDonate = (e: any) => build(Type.onDonate, e)

    /**
     * 跳轉請我們喝咖啡 (藍新)
     */
    const onCoffee = (e: any) => build(Type.onCoffee, e)

    /**
     * 開發團隊 - 跳轉
     */
    const onDevelopOpen = (e: any) => build(Type.onDevelopOpen, e)

    /**
     * 開發團隊 - 請我們喝咖啡
     */
    const onDeveloperCoffee = (e: any) => build(Type.onDeveloperCoffee, e)

    /**
     * 開啟store
     */
    const onStoreOpen = () => build(Type.onStoreOpen)

    /**
     * 掃碼成功
     */
    const onFactorySuccess = () => build(Type.onFactorySuccess)

    /**
     * 搜尋 - 首頁 - 聖經
     */
    const onSearchHomeBible = (e: string) => build(Type.onSearchHomeBible, e)

    /**
     * 教學 - 完成 - 提交
     */
    const onTeachSubmit = () => build(Type.onTeachSubmit)

    /**
     * 註冊 - 設置 - 頭像
     */
    const onAvatar = (e: string) => build(
        Type.onAvatar,
        e
    )

    /**
     * 註冊 - 捲軸 - 上鎖
     */
    const onRegisterScrollLock = () => build(Type.onRegisterScrollLock)

    /**
     * 註冊 - 捲軸 - 解鎖
     */
    const onRegisterScrollUnLock = () => build(Type.onRegisterScrollUnLock)

    /**
     * 註冊 - 返回 - 上鎖
     */
    const onRegisterBackLock = () => build(Type.onRegisterBackLock)

    /**
     * 註冊 - 返回 - 解鎖
     */
    const onRegisterBackUnLock = () => build(Type.onRegisterBackUnLock)

    /**
     * 註冊 - 放棄
     */
    const onRegisterDismiss = () => build(Type.onRegisterDismiss)

    /**
     * 註冊 - 成功
     */
    const onRegisterSuccess = (e: string) => build(
        Type.onRegisterSuccess,
        e
    )

    /**
     * 忘記密碼 - 成功
     */
    const onForgotSuccess = () => build(
        Type.onForgotSuccess
    )

    /**
     * 我的 - 個人資料 - 更換 - 名稱
     * @param e 頭像網址
     */
    const onMyProfileName = (e: string) => build(
        Type.onMyProfileName,
        e
    )

    /**
     * 我的 - 個人資料 - 更換 - 頭像
     * @param e 頭像網址
     */
    const onMyProfileAvatar = (e: string) => build(
        Type.onMyProfileAvatar,
        e
    )

    /**
     * 我的 - 實用工具 - 設置 - 文章 - 收藏 - 通知
     * @param e 頭像網址
     */
    const onMyEasySettingArticleComment = (e: boolean) => build(
        Type.onMyEasySettingArticleComment,
        JSON.stringify(e)
    )

    /**
     * 我的 - 實用工具 - 設置 - 封鎖名單 - 解除
     * @param e 執行uuid
     */
    const onMyEasySettingSlashRemove = (e: string[]) => build(
        Type.onMyEasySettingSlashRemove,
        JSON.stringify(e)
    )

    /**
     * 我的 - 實用工具 - 設置 - 封鎖名單 - 刪除
     * @param e 執行uuid
     */
    const onMyEasySettingSlashDelete = (e: string[]) => build(
        Type.onMyEasySettingSlashDelete,
        JSON.stringify(e)
    )

    /**
     * 聚會 - 自評 - 完成
     * @param e 點名表uuid
     */
    const onAttendRankComplete = (e: any) => build(
        Type.onAttendRankComplete,
        e
    )

    /**
     * 聚會 - 自評 - 完成
     * @param e uuid 聚會識別碼, rollcall 點名單識別碼
     */
    const onAttendRollCallEditScan = (e: any) => build(
        Type.onAttendRollCallEditScan,
        JSON.stringify(e)
    )

    /**
     * 登出
     */
    const onLogout = () => build(Type.onLogout)

    /**
     * 刪除帳號
     */
    const onDeleteAccount = () => build(Type.onDeleteAccount)

    /**
     * 返回
     */
    const onBackPress = () => build(Type.onBackPress)

    const build = (
        e: Type,
        value?: string | null
    ) => {

        //注意要壓成JSON字串傳下去
        const message = JSON.stringify({
            key: e,
            value: value
        })

        if (!isProd) console.log("useJsBridge: ", JSON.parse(message))

        //@ts-ignore
        //iOS
        if (window.webkit && window.webkit.messageHandlers[jsBridgeKey]) window.webkit.messageHandlers[jsBridgeKey].postMessage(message);
        //@ts-ignore
        //Android
        else if (window[jsBridgeKey]) window[jsBridgeKey].postMessage(message)

    }

    return {
        onError404,
        onTakeOver,
        onStarted,
        onFinished,
        onNextTick,
        onReceivedError,
        onUnauthorized,
        onRequestedOrientation,
        onRotatePortrait,
        onRotateLandscape,
        onImageDownload,
        onHyperlink,
        onLive,
        onShare,
        onFavoriteSave,
        onFavoriteRecycle,
        onBibleUpgrade,
        onTheme,
        onFont,
        onActivityBuy,
        onComment,
        onCommentComplete,
        onBackground,
        onNavigation,
        onNavigationBackground,
        onNavigationDonateRecordHide,
        onPasscode,
        onLocale,
        onStorage,
        onScan,
        onCamera,
        onAlbum,
        onPasswd,
        onChurchHyperlink,
        onDonate,
        onCoffee,
        onDevelopOpen,
        onDeveloperCoffee,
        onStoreOpen,
        onFactorySuccess,
        onSearchHomeBible,
        onTeachSubmit,
        onAvatar,
        onRegisterScrollLock,
        onRegisterScrollUnLock,
        onRegisterBackLock,
        onRegisterBackUnLock,
        onRegisterDismiss,
        onRegisterSuccess,
        onForgotSuccess,
        onMyProfileName,
        onMyProfileAvatar,
        onMyEasySettingArticleComment,
        onMyEasySettingSlashRemove,
        onMyEasySettingSlashDelete,
        onAttendRankComplete,
        onAttendRollCallEditScan,
        onLogout,
        onDeleteAccount,
        onBackPress
    }

}
