export default {
    download: {
        image: {
            true: {
                subject: "圖片下載成功"
            },
            false: {
                subject: "圖片下載失敗"
            }
        }
    },
    dplayer: {
        error: {
            unknown: "發生未知錯誤",
            live: {
                not: {
                    yet: "直播尚未開始"
                }
            },
            video: {
                private: "私人影片無法播放",
                not: {
                    yet: "影片尚未開始"
                }
            }
        },
        refresh: "重新讀取"
    },
    loading: {
        subject: "正在讀取中...請稍候",
        newebpay: {
            subject: "正在連接藍新金流",
            text: "請稍候"
        }
    },
    audio: {
        error: {
            unknown: "發生未知錯誤",
        },
        refresh: "重新讀取"
    },
    passwd: {
        strong: {
            subject: "密碼強度未達標",
            text: {
                1: `密碼需要 <b class="ft-16 text-danger">%d</b> 個字元以上`,
                2: `密碼需要 <b class="ft-16 text-danger">%d</b> 個字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 個英文字與數字`,
                3: `密碼需要 <b class="ft-16 text-danger">%d</b> 個字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 個大小寫英文與數字`,
                4: `密碼需要 <b class="ft-16 text-danger">%d</b> 個字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 個大小寫英文, 數字與符號`,
                5: `密碼需要 <b class="ft-16 text-danger">%d</b> 個字元以上\n以及至少 <b class="ft-16 text-danger">1</b> 個大小寫英文, 數字與符號\n且不得連續出現相同字元`
            }
        }
    },
    home: {
        search: {
            bible: {
                subject: `聖經搜尋 - 結果共計 <b class="ft-15 text-success">%s</b> 筆`,
                empty: {
                    subject: "聖經搜尋 - 找不到結果"
                }
            },
            empty: {
                subject: `搜尋不到指定的文章`,
            }
        }
    },
    attend: {
        entry: {
            field: {
                begin: "開始時間",
                end: "結束時間"
            },
            status: {
                0: "未開始",
                1: "進行中",
                2: "已結束",
                3: "無聚會",
                rank: {
                    0: "未完成",
                    1: "已完成"
                }
            }
        }
    },
    article: {
        entry: {
            type: [{

            }, {
                subject: "圖文",
            }, {
                subject: "影音",
            }, {
                subject: "MP3",
            },{
                subject: "直播",
            },{
                subject: "網頁",
            }]
        }
    },
    register: {
        email: {
            subject: "使用電子郵件註冊",
            holder: "請輸入您的電子郵件",
            failure: {
                subject: "發生異常錯誤\n請稍後再試",
            }
        },
        invitation: {
            subject: "請輸入您所在教會提供的邀請碼",
            text: "共 %s 個字元",
            description: "點擊小格子即可以開始輸入",
            failure: {
                subject: "發生異常錯誤\n請稍後再試",
            }
        },
        verification: {
            subject: "已將驗證信寄送至您的電子郵件",
            text: "請輸入郵件中的驗證碼, 共 %s 位數字",
            description: "點擊小格子即可以開始輸入",
            failure: {
                subject: "發生異常錯誤\n請稍後再試",
            }
        },
        welcome: {
            subject: "歡迎使用我的教會App",
            text: "註冊即將開始",
            submit: {
                subject: "開始註冊"
            }
        },
        avatar: {
            subject: "設置頭像",
            take: {
                1: "相冊",
                2: "相機"
            }
        },
        name: {
            subject: "設置姓名",
            holder: "請輸入您的姓名"
        },
        passwd: {
            subject: "設置登入密碼",
            notice: {
                bar: {
                    success: "密碼一致",
                    error: "密碼不一致",
                    failure: "請輸入密碼"
                }
            },
            holder: {
                current: "請輸入登入密碼",
                confirm: "請再輸入一次密碼進行確認",
            }
        },
        church: {
            subject: "設置所在教會",
            text: "我沒有所屬教會",
            location: {
                subject: "所在區域",
                holder: {
                    subject: "請選擇所在區域"
                }
            },
            entry: {
                subject: "所在教會",
                holder: {
                    subject: "請選擇所在教會"
                },
                entry: {
                    subject: "選擇所在教會",
                    country: {
                        subject: "所在區域",
                        holder: {
                            subject: "請選擇所在區域"
                        }
                    },
                    city: {
                        subject: "所在城市",
                        holder: {
                            subject: "請選擇所在城市"
                        }
                    },
                    keyword: {
                        subject: "關鍵字",
                        holder: {
                            subject: "輸入關鍵字進行搜尋"
                        }
                    },
                    available: {
                        subject: "教會名單",
                        holder: {
                            subject: "請選擇您所在的教會"
                        }
                    }
                }
            }
        },
        personal: {
            subject: "設置個人資訊",
            title: {
                subject: "稱謂",
                holder: {
                    subject: "請選擇稱謂"
                }
            },
            birthday: {
                subject: "生日",
                holder: {
                    subject: "請選擇生日"
                }
            },
            phone: {
                subject: "電話",
                holder: {
                    subject: "請輸入電話"
                }
            },
            location: {
                subject: "所在區域",
                holder: {
                    subject: "請選擇所在區域"
                }
            },
            address: {
                subject: "地址",
                holder: {
                    subject: "請輸入地址"
                }
            },
            occupation: {
                subject: "職業",
                holder: {
                    subject: "請選擇職業"
                }
            },
            baptized: {
                subject: "受洗日期",
                holder: {
                    subject: "請選擇受洗日期"
                }
            }
        },
        done: {
            subject: "太好了🎉🎉🎉",
            text: "即將完成註冊",
            submit: {
                subject: "完成註冊"
            },
            success: {
                subject: "註冊成功",
            },
            failure: {
                subject: "註冊失敗\n請稍後再試",
            }
        }
    },
    forgot: {
        email: {
            subject: "別擔心, 輸入您的註冊電子郵件",
            text: "我們將寄送驗證碼給您, 進行重設密碼",
            holder: "請輸入您的電子郵件",
            failure: {
                subject: "發生異常錯誤\n請稍後再試",
            }
        },
        verification: {
            subject: "已將驗證信寄送至您的電子郵件",
            text: "請輸入郵件中的驗證碼, 共 %s 位數字",
            description: "點擊小格子即可以開始輸入",
            failure: {
                subject: "發生異常錯誤\n請稍後再試",
            }
        },
        passwd: {
            subject: "重設登入密碼",
            notice: {
                bar: {
                    success: "密碼一致",
                    error: "密碼不一致",
                    failure: "請輸入密碼"
                }
            },
            holder: {
                current: "請輸入登入密碼",
                confirm: "請再輸入一次密碼進行確認",
            }
        },
        done: {
            subject: "太好了🎉🎉🎉",
            text: "即將完成重設密碼",
            submit: {
                subject: "確認重設"
            },
            success: {
                subject: "重設密碼成功",
                text: "請您使用新密碼登入"
            },
            failure: {
                subject: "重設密碼失敗\n請稍後再試",
            }
        }
    },
    font: {
        picker: {
            size: {
                1: "小",
                2: "中",
                3: "大",
                4: "特大",
            }
        }
    },
    take: {
        camera: {
            subject: "相機"
        },
        gallery: {
            subject: "相冊"
        }
    },
    my: {
        easy: {
            leader: {
                shepherd: {
                    summary: {
                        team: {
                            subject: "族群"
                        },
                        attend: {
                            subject: "聚會"
                        },
                        partake: {
                            subject: "成員"
                        },
                        manager: {
                            subject: "託管"
                        }
                    },
                    edit: {
                        rollcall: {
                            field: {
                                begin: "開始時間",
                                end: "結束時間"
                            },
                            status: {
                                0: "未開始",
                                1: "進行中",
                                2: "已結束",
                                3: "無聚會",
                                rank: {
                                    0: "未完成",
                                    1: "已完成"
                                }
                            }
                        }
                    }
                }
            },
            setting: {
                black: {
                    remove: "解除"
                }
            }
        }
    },
    blank: {
        section: {
            subject: "暫時找不到您要的資料",
            text: "重新讀取"
        }
    }
}
