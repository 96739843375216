export default {
    nav: {
        subject: "聚會自評"
    },
    field: {
        attend: {
            subject: "請選擇聚會"
        }
    },
    edit: {
        nav: {
            subject: "自評內容"
        },
        notice: {
            subject: "聚會需要在開始之後才可以進行評分"
        },
        field: {
            rank: "請為今天聚會的自己打個分數",
            note: "說些什麼"
        },
        holder: {
            rank: "點擊或拖曳星星進行打分數, 星星越多代表分數越高",
            note: "代禱、聚會心得、建議或其他"
        },
        success: {
            subject: "評分成功"
        },
        failure: {
            subject: "評分失敗"
        },
        handler: {
            submit: {
                subject: "即將進行評分",
                text: "請確認後執行"
            }
        }
    }
}
