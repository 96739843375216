export default {
    status: {
        0: {
            subject: "優惠已經逾期"
        },
        1: {
            subject: "優惠即將逾期, 請儘快報名"
        },
        2: {
            subject: "優惠尚未開始"
        }
    },
    subject: "報名費用",
    note: {
        subject: "優惠備註"
    },
    during: {
        subject: "%s 至 %s",
        first: {
            subject: "%s 之前(含)"
        },
        last: {
            subject: "%s 之後(含)"
        }
    }
}
