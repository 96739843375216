import {RouteRecordRaw} from "vue-router";

const Leader: RouteRecordRaw[] = [{
    path: "my/easy/leader",
    name: "H5EasyLeader",
    component: () => import("@/page/h5/my/easy/leader/index.vue")
}, {
    path: "my/easy/leader/shepherd",
    name: "H5EasyLeaderShepherd",
    component: () => import("@/page/h5/my/easy/leader/shepherd/index.vue"),
}, {
    path: "my/easy/leader/shepherd/edit/:uuid",
    name: "H5EasyLeaderShepherdEdit",
    component: () => import("@/page/h5/my/easy/leader/shepherd/edit/index.vue"),
}, {
    path: "my/easy/leader/shepherd/edit/:uuid/rollcall",
    name: "H5EasyLeaderShepherdEditRollCall",
    component: () => import("@/page/h5/my/easy/leader/shepherd/edit/rollcall/index.vue"),
}, {
    path: "my/easy/leader/shepherd/edit/:uuid/rollcall/edit/:uid",
    name: "H5EasyLeaderShepherdEditRollCallEdit",
    component: () => import("@/page/h5/my/easy/leader/shepherd/edit/rollcall/edit/index.vue"),
}, {
    path: "my/easy/leader/chart",
    name: "H5EasyLeaderChart",
    component: () => import("@/page/h5/my/easy/leader/shepherd/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid",
    name: "H5EasyLeaderChartEdit",
    component: () => import("@/page/h5/my/easy/leader/chart/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/attend",
    name: "H5EasyLeaderChartEditAttend",
    component: () => import("@/page/h5/my/easy/leader/chart/attend/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/event",
    name: "H5EasyLeaderChartEditEvent",
    component: () => import("@/page/h5/my/easy/leader/chart/event/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/gender",
    name: "H5EasyLeaderChartEditGender",
    component: () => import("@/page/h5/my/easy/leader/chart/gender/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/year",
    name: "H5EasyLeaderChartEditYear",
    component: () => import("@/page/h5/my/easy/leader/chart/year/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/deacon",
    name: "H5EasyLeaderChartEditDeacon",
    component: () => import("@/page/h5/my/easy/leader/chart/deacon/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/education",
    name: "H5EasyLeaderChartEditEducation",
    component: () => import("@/page/h5/my/easy/leader/chart/education/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/occupation",
    name: "H5EasyLeaderChartEditOccupation",
    component: () => import("@/page/h5/my/easy/leader/chart/occupation/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/marital",
    name: "H5EasyLeaderChartEditMarital",
    component: () => import("@/page/h5/my/easy/leader/chart/marital/index.vue"),
}, {
    path: "my/easy/leader/chart/edit/:uuid/baptized",
    name: "H5EasyLeaderChartEditBaptized",
    component: () => import("@/page/h5/my/easy/leader/chart/baptized/index.vue"),
}]

export default Leader
