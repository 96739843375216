import shepherd from "@/language/modules/page/my/easy/leader/shepherd/zhCN"

export default {
    nav: {
        subject: "领袖"
    },
    item: {
        shepherd: {
            subject: "牧养族群"
        },
        chart: {
            subject: "牧养跟进"
        }
    },
    handler: {
      protect: {
          subject: "您即将查看会友资讯",
          text: "我将妥善与谨慎使用相关资讯",
      }
    },
    submit: {
        accept: "了解并同意",
        cancel: "我不同意"
    },
    shepherd: shepherd,
    chart: {
        nav: {
            subject: "牧养跟进"
        },
        item: {
            attend: {
                subject: "聚会纪录",
                field: {
                    subject: "族群",
                    total: "合计次数",
                    last: "最后刷新时间"
                },
                period: {
                    week: "周",
                    month: "月",
                    season: "季"
                }
            },
            event: {
                subject: "服事参与",
                help: {
                    updated: "最后刷新时间"
                },
                empty: {
                    subject: "暂无资料"
                },
                tip: {
                    team: `列表对象为群组第一层全教会各占比，点选群组名称可查阅下一级群组<br/><span class="text-danger">该牧区计算: 参与服事人数 / 总人数 = 全教会服事比例</span>`
                },
                header: {
                    team: {
                        subject: "统计服事对象"
                    },
                    temperature: {
                        subject: "温度服事对象",
                        info: "30天以上未参加",
                        warning: "60天以上未参加",
                        danger: "90天以上未参加",
                        total: {
                            all: {
                                subject: `合计 <b class="text-success ft-15">%s</b> 位`
                            },
                            section: {
                                subject: `共 <b class="text-%s ft-15">%s</b> 位`
                            }
                        }
                    },
                    leave: {
                        subject: "离开服事对象",
                        total: {
                            all: {
                                subject: `合计 <b class="text-danger ft-15">%s</b> 位`
                            }
                        }
                    },
                    join: {
                        month: {
                            subject: `创建服事人数 <span class="ft-14">(月)</span>`,
                            total: {
                                all: {
                                    subject: `合计 <b class="text-success ft-15">%s</b> 位`
                                }
                            }
                        },
                        week: {
                            subject: `创建服事人数 <span class="ft-14">(周)</span>`,
                            total: {
                                all: {
                                    subject: `合计 <b class="text-success ft-15">%s</b> 位`
                                }
                            }
                        }
                    },
                    event: {
                        team: {
                            subject: "参与群组",
                            total: {
                                all: {
                                    subject: `合计 <b class="text-info ft-15">%s</b> 位`
                                }
                            }
                        }
                    }
                },
                summary: {
                    total: {
                        subject: "总人数"
                    },
                    having: {
                        subject: "参与服事人数"
                    },
                    nothing: {
                        subject: "未参与服事人数"
                    },
                    percent: {
                        current: {
                            subject: `参与服事比例 <span class="ft-12">(%)</span>`
                        },
                        prev: {
                            subject: `前月参与服事比例 <span class="ft-12">(%)</span>`
                        }
                    },
                    join: {
                        month: {
                            subject: `创建服事人数 <span class="ft-12">(月)</span>`
                        },
                        week: {
                            subject: `创建服事人数 <span class="ft-12">(周)</span>`
                        }
                    },
                    leave: {
                        subject: "离开服事人数"
                    }
                }
            },
            gender: {
                subject: "男女比例",
                field: {
                    subject: "性别",
                    quantity: "数量",
                    percent: "占比",
                    total: "总和",
                    last: "最后刷新时间",
                },
                option: {
                    1: "弟兄",
                    2: "姐妹",
                    9999: "未登记"
                }
            },
            year: {
                subject: "年龄分布",
                field: {
                    subject: "年龄",
                    quantity: "数量",
                    percent: "占比",
                    total: "总和",
                    last: "最后刷新时间",
                },
                option: {
                    0: "0-9岁",
                    1: "10-19岁",
                    2: "20-29岁",
                    3: "30-39岁",
                    4: "40-49岁",
                    5: "50-59岁",
                    6: "60-69岁",
                    7: "70-79岁",
                    8: "80-89岁",
                    9: "90-99岁",
                    10: "100-109岁",
                    11: "110-119岁",
                    12: "120-129岁",
                    9999: "未登记"
                }
            },
            deacon: {
                subject: "五重执事",
                field: {
                    subject: "执事",
                    quantity: "数量",
                    percent: "占比",
                    total: "总和",
                    last: "最后刷新时间"
                },
                option: {
                    1: "使徒",
                    2: "先知",
                    3: "传福音",
                    4: "牧师",
                    5: "教师",
                    9999: "未登记"
                }
            },
            education: {
                subject: "教育程度",
                field: {
                    subject: "程度",
                    quantity: "数量",
                    percent: "占比",
                    total: "总和",
                    last: "最后刷新时间"
                },
                option: {
                    1: "小学",
                    2: "中学",
                    3: "专科",
                    4: "高中",
                    5: "高职/高商",
                    6: "大学",
                    7: "硕士",
                    8: "博士",
                    9999: "未登记"
                }
            },
            occupation: {
                subject: "职业分布",
                field: {
                    subject: "职业",
                    quantity: "数量",
                    percent: "占比",
                    total: "总和",
                    last: "最后刷新时间"
                },
                option: {
                    1: "行政经营",
                    2: "业务行销",
                    3: "人事法务",
                    4: "财会金融",
                    5: "广告美编",
                    6: "客户服务",
                    7: "电脑硬体",
                    8: "资讯软体",
                    9: "品管制造",
                    10: "技术服务",
                    11: "营建职业",
                    12: "传播媒体",
                    13: "娱乐演艺",
                    14: "教育学术",
                    15: "医疗美容",
                    16: "保全警卫",
                    17: "家事服务",
                    18: "农林鱼牧",
                    19: "慈善宗教",
                    20: "交通及物流服务",
                    21: "餐饮旅游运动",
                    22: "美容美发",
                    23: "军公教",
                    56: "家管",
                    57: "儿童",
                    58: "待业",
                    59: "学生",
                    9999: "未登记"
                }
            },
            marital: {
                subject: "婚姻状况",
                field: {
                    subject: "状况",
                    quantity: "数量",
                    percent: "占比",
                    total: "总和",
                    last: "最后刷新时间"
                },
                option: {
                    1: "已婚",
                    2: "未婚",
                    3: "丧偶",
                    4: "再婚",
                    5: "离婚",
                    9999: "未登记"
                }
            },
            baptized: {
                subject: "受洗人数"
            }
        }
    }
}
