import home from "./home/zhTW"
import article from "./article/zhTW"
import my from "./my/zhTW"
import message from "./message/zhTW"
import factory from "./factory/zhTW"
import register from "./register/zhTW"
import attend from "./attend/zhTW"
import forgot from "./forgot/zhTW"

export default {
    home: home,
    article: article,
    my: my,
    message: message,
    factory: factory,
    register: register,
    attend: attend,
    forgot: forgot
}
