export default {
    nav: {
        subject: "掃碼登入"
    },
    idle: {
        subject: {
            subject: "我的教會App",
            text: "於其他設備登入",
        },
        text: {
            subject: "請確認本人操作",
            text: "並確保行動條碼來源安全"
        },
        submit: "確認登入",
        cancel: "取消"
    },
    error: {
        subject: "掃碼登入檢測異常",
        text: {
            subject: "可能權杖超時或不存在",
            text: "請重新掃描或請稍後再試",
            description: `如果您尚未綁定管理員<br />請先使用帳號密碼登入進行綁定帳號`
        },
        submit: "返回"
    },
    success: {
        subject: "登入成功"
    },
    failure: {
        subject: "登入失敗"
    }
}
