import shepherd from "@/language/modules/page/my/easy/leader/shepherd/zhTW"

export default {
    nav: {
        subject: "領袖"
    },
    item: {
        shepherd: {
            subject: "牧養族群"
        },
        chart: {
            subject: "牧養跟進"
        }
    },
    handler: {
      protect: {
          subject: "您即將查看會友資訊",
          text: "我將妥善與謹慎使用相關資訊",
      }
    },
    submit: {
        accept: "瞭解並同意",
        cancel: "我不同意"
    },
    shepherd: shepherd,
    chart: {
        nav: {
            subject: "牧養跟進"
        },
        item: {
            attend: {
                subject: "聚會紀錄",
                field: {
                    subject: "族群",
                    total: "合計次數",
                    last: "最後更新時間"
                },
                period: {
                    week: "週",
                    month: "月",
                    season: "季"
                }
            },
            event: {
                subject: "服事參與",
                help: {
                    updated: "最後更新時間"
                },
                empty: {
                    subject: "暫無資料"
                },
                tip: {
                    team: `列表對象為群組第一層全教會各佔比，點選群組名稱可查閱下一級群組<br/><span class="text-danger">該牧區計算: 參與服事人數 / 總人數 = 全教會服事比例</span>`
                },
                header: {
                    team: {
                        subject: "統計服事對象"
                    },
                    temperature: {
                        subject: "溫度服事對象",
                        info: "30天以上未參加",
                        warning: "60天以上未參加",
                        danger: "90天以上未參加",
                        total: {
                            all: {
                                subject: `合計 <b class="text-success ft-15">%s</b> 位`
                            },
                            section: {
                                subject: `共 <b class="text-%s ft-15">%s</b> 位`
                            }
                        }
                    },
                    leave: {
                        subject: "離開服事對象",
                        total: {
                            all: {
                                subject: `合計 <b class="text-danger ft-15">%s</b> 位`
                            }
                        }
                    },
                    join: {
                        month: {
                            subject: `新增服事人數 <span class="ft-14">(月)</span>`,
                            total: {
                                all: {
                                    subject: `合計 <b class="text-success ft-15">%s</b> 位`
                                }
                            }
                        },
                        week: {
                            subject: `新增服事人數 <span class="ft-14">(週)</span>`,
                            total: {
                                all: {
                                    subject: `合計 <b class="text-success ft-15">%s</b> 位`
                                }
                            }
                        }
                    },
                    event: {
                        team: {
                            subject: "參與群組",
                            total: {
                                all: {
                                    subject: `合計 <b class="text-info ft-15">%s</b> 位`
                                }
                            }
                        }
                    }
                },
                summary: {
                    total: {
                        subject: "總人數"
                    },
                    having: {
                        subject: "參與服事人數"
                    },
                    nothing: {
                        subject: "未參與服事人數"
                    },
                    percent: {
                        current: {
                            subject: `參與服事比例 <span class="ft-12">(%)</span>`
                        },
                        prev: {
                            subject: `前月參與服事比例 <span class="ft-12">(%)</span>`
                        }
                    },
                    join: {
                        month: {
                            subject: `新增服事人數 <span class="ft-12">(月)</span>`
                        },
                        week: {
                            subject: `新增服事人數 <span class="ft-12">(週)</span>`
                        }
                    },
                    leave: {
                        subject: "離開服事人數"
                    }
                }
            },
            gender: {
                subject: "男女比例",
                field: {
                    subject: "性別",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間",
                },
                option: {
                    1: "弟兄",
                    2: "姐妹",
                    9999: "未登記"
                }
            },
            year: {
                subject: "年齡分佈",
                field: {
                    subject: "年齡",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間",
                },
                option: {
                    0: "0-9歲",
                    1: "10-19歲",
                    2: "20-29歲",
                    3: "30-39歲",
                    4: "40-49歲",
                    5: "50-59歲",
                    6: "60-69歲",
                    7: "70-79歲",
                    8: "80-89歲",
                    9: "90-99歲",
                    10: "100-109歲",
                    11: "110-119歲",
                    12: "120-129歲",
                    9999: "未登記"
                }
            },
            deacon: {
                subject: "五重執事",
                field: {
                    subject: "執事",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "使徒",
                    2: "先知",
                    3: "傳福音",
                    4: "牧師",
                    5: "教師",
                    9999: "未登記"
                }
            },
            education: {
                subject: "教育程度",
                field: {
                    subject: "程度",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "小學",
                    2: "中學",
                    3: "專科",
                    4: "高中",
                    5: "高職/高商",
                    6: "大學",
                    7: "碩士",
                    8: "博士",
                    9999: "未登記"
                }
            },
            occupation: {
                subject: "職業分佈",
                field: {
                    subject: "職業",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "行政經營",
                    2: "業務行銷",
                    3: "人事法務",
                    4: "財會金融",
                    5: "廣告美編",
                    6: "客戶服務",
                    7: "電腦硬體",
                    8: "資訊軟體",
                    9: "品管製造",
                    10: "技術服務",
                    11: "營建職業",
                    12: "傳播媒體",
                    13: "娛樂演藝",
                    14: "教育學術",
                    15: "醫療美容",
                    16: "保全警衛",
                    17: "家事服務",
                    18: "農林魚牧",
                    19: "慈善宗教",
                    20: "交通及物流服務",
                    21: "餐飲旅遊運動",
                    22: "美容美髮",
                    23: "軍公教",
                    56: "家管",
                    57: "兒童",
                    58: "待業",
                    59: "學生",
                    9999: "未登記"
                }
            },
            marital: {
                subject: "婚姻狀況",
                field: {
                    subject: "狀況",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "已婚",
                    2: "未婚",
                    3: "喪偶",
                    4: "再婚",
                    5: "離婚",
                    9999: "未登記"
                }
            },
            baptized: {
                subject: "受洗人數"
            }
        }
    }
}
