/**
 * 工具
 */
export default {
    date: {
        message: {
            just: "刚刚",
            today: "今天",
            yesterday: "昨天",
            week: {
                1: "星期日",
                2: "星期一",
                3: "星期二",
                4: "星期三",
                5: "星期四",
                6: "星期五",
                7: "星期六",
            },
            date: "%s/%s (%s)",
            full: {
                date: "%s年%s月%s日 (%s)"
            }
        },
        ymd: "%04d年%02d月%02d日 %02d:%02d"
    }
}
