import activity from "./activity/zhCN"
import setting from "./setting/zhCN"
import developer from "./developer/zhCN"
import feedback from "./feedback/zhCN"
import privacy from "./privacy/zhCN"
import donate from "./donate/zhCN"
import profile from "./profile/zhCN"
import belong from "./belong/zhCN"
import leader from "./leader/zhCN"

export default {
    activity: activity,
    leader: leader,
    belong: belong,
    profile: profile,
    donate: donate,
    privacy: privacy,
    feedback: feedback,
    setting: setting,
    developer: developer
}
