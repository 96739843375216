export default {
    activity: {
        subject: "提交",
        expire: {
            subject: "报名截止",
        },
        full: {
            subject: "已经额满",
        }
    },
    download: "下载",
    payment: "线上付款",
    loading: `处理中\n请稍候`,
    save: "保存",
    accept: "确定",
    passwd: "变更密码",
    return: "返回",
    send: "提交",
    submit: "提交",
    close: "关闭",
    cancel: "取消",
    good: "好",
    checkout: "结帐",
    ignore: "略过",
    next: "下一步",
    copy: {
        subject: "复制成功",
        text: "复制"
    },
    step: {
        prev: "上一步",
        next: "下一步"
    },
    register: {
        subject: "新会友_%s",
    }
}
