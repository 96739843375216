export default {
    nav: {
        subject: "意見與建議"
    },
    subject: "內容",
    holder: "給教會寶貴的意見",
    success: {
        subject: "儲存成功"
    },
    failure: {
        subject: "儲存失敗"
    }
}
