<template>
  <div v-if="isLogin">
    <!-- 記得要加上key, 這樣他才會認為每一頁不同 -->
    <router-view
        :key="$route.fullPath"
        v-slot="{Component}">
      <component
          ref="page"
          :is="Component" />
    </router-view>
    <van-back-top
        v-if="isShowBackTop"
        right="1rem"
        :bottom="getBackTopBottom">
      <icon-sax name="enable" />
    </van-back-top>
  </div>
  <not-found v-else />
</template>

<script setup lang="ts">

import NotFound from "@/page/h5/404/index.vue"
import IconSax from "@/components/IconSax.vue"
import {computed, reactive, ref} from "vue";
import {useSetting} from "@/hooks/useSetting";
import {useRoute} from "vue-router";

const {
    onExploit,
    getToken,
    setDarkTheme,
    setFollowTheme,
    setSystemTheme,
    isSupplierAndroid
} = useSetting()

const page = ref()

const route = useRoute()

/**
 * 參數 - 本地
 */
const active = reactive<{
  i18n: string
}>({
  i18n: "page.launch",
})

/**
 * 判斷是頁面是否需要登入
 */
const isLogin = computed(() : boolean => {

  //⚠️⚠️ 注意 ⚠️⚠️
  //如果不需要token的頁面
  //請到 route 設定 meta.right = false
  if (route.meta.right === false) return true

  return ![
    "",
    null
  ].includes(getToken.value)

})

/**
 * 返回 - BackTop - 顯示的條件
 */
const isShowBackTop = computed(() :boolean => {
    return isSupplierAndroid.value
})

/**
 * 返回 - BackTop - 底部高度
 */
const getBackTopBottom = computed((): string => {
    if (page.value && page.value.getBackTopBottom) return page.value.getBackTopBottom
    return `1rem`
})

/**
 * 執行 - 透析 - 參數
 */
onExploit()

//@ts-ignore
//交互 - 呼叫 - 設定 - 主題
window.bridgeSetDarkTheme = (e: boolean) => setDarkTheme(e)

//@ts-ignore
//交互 - 呼叫 - 設定 - 主題 - 跟隨
window.bridgeSetThemeFollow = (e: boolean) => setFollowTheme(e)

//@ts-ignore
//交互 - 呼叫 - 設定 - 主題 - 系統
window.bridgeSetThemeSystem = (e: boolean) => setSystemTheme(e)

</script>

<style scoped lang="sass">
</style>
