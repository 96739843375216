export default {
    activity: {
        subject: "提交",
        expire: {
            subject: "報名截止",
        },
        full: {
            subject: "已經額滿",
        }
    },
    download: "下載",
    payment: "線上付款",
    loading: `處理中\n請稍候`,
    save: "儲存",
    accept: "確定",
    passwd: "變更密碼",
    return: "返回",
    send: "送出",
    submit: "送出",
    close: "關閉",
    cancel: "取消",
    good: "好",
    checkout: "結帳",
    ignore: "略過",
    next: "下一步",
    copy: {
        subject: "複製成功",
        text: "複製"
    },
    step: {
        prev: "上一步",
        next: "下一步"
    },
    register: {
        subject: "新會友_%s",
    }
}
