/**
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 字型
 */
export enum EnumFontSize {

    /**
     * 小
     */
    small = 1,

    /**
     * 中
     */
    middle,

    /**
     * 大
     */
    large,

    /**
     * 特大
     */
    extra

}
