export default {
    nav: {
        subject: "聚會簽到"
    },
    field: {
        attend: {
            subject: "請選擇聚會"
        }
    },
    handler: {
        prevent: {
            subject: "本場聚會不能自行簽到",
            text: "請向小組長掃碼報到或掃描二維碼報到"
        },
        signature: {
            subject: "即將進行聚會簽到",
            text: "請確認您已到場後再後執行簽到"
        }
    },
    success: {
        signature: {
            subject: "簽到成功"
        }
    },
    failure: {
        signature: {
            subject: "簽到失敗"
        }
    }
}
