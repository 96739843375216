import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

import Content from '@/page/h5/index.vue'
import NotFound from '@/page/h5/404/index.vue'

import Control from "@/router/modules/control/index";
import Home from "@/router/modules/home/index";
import Article from "@/router/modules/article/index";
import My from "@/router/modules/my/index";
import Message from "@/router/modules/message/index";
import Factory from "@/router/modules/factory/index";
import Teach from "@/router/modules/teach/index";
import Forgot from "@/router/modules/forgot/index";
import Register from "@/router/modules/register/index";
import Attend from "@/router/modules/attend/index";

const routes: RouteRecordRaw[] = [{
    path: "/h5",
    name: "H5Content",
    component: Content,
    children: [
        ...Control,
        ...Home,
        ...Article,
        ...My,
        ...Message,
        ...Factory,
        ...Teach,
        ...Forgot,
        ...Register,
        ...Attend
    ]
}, {
    // 404頁面
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
}]

/**
 * 創建路由
 * 如果網址後面有個# 請改用 createWebHistory
 */
const router = createRouter({
    history: createWebHistory(),
    routes,
    strict: false,
    // 切換頁面，滾動到最頂部
    scrollBehavior: () => ({ left: 0, top: 0 })
});

export default router;
