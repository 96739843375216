export default {
    nav: {
        subject: "所属教会"
    },
    handler: {
        submit: {
            subject: "即将前往关于我们网页",
            text: "确定要前往吗?"
        }
    }
}
