import home from "./home/zhCN"
import article from "./article/zhCN"
import my from "./my/zhCN"
import message from "./message/zhCN"
import factory from "./factory/zhCN"
import register from "./register/zhCN"
import attend from "./attend/zhCN"
import forgot from "./forgot/zhCN"

export default {
    home: home,
    article: article,
    my: my,
    message: message,
    factory: factory,
    register: register,
    attend: attend,
    forgot: forgot
}
