export default {
    nav: {
        subject: "扫码登入"
    },
    idle: {
        subject: {
            subject: "我的教会App",
            text: "于其他设备登入",
        },
        text: {
            subject: "请确认本人操作",
            text: "并确保行动条码来源安全"
        },
        submit: "确认登入",
        cancel: "取消"
    },
    error: {
        subject: "扫码登入检测异常",
        text: {
            subject: "可能权杖超时或不存在",
            text: "请重新扫描或请稍后再试",
            description: `如果您尚未绑定管理员<br />请先使用帐户密码登入进行绑定帐户`
        },
        submit: "返回"
    },
    success: {
        subject: "登入成功"
    },
    failure: {
        subject: "登入失败"
    }
}
