import {RouteRecordRaw} from "vue-router";

const Teach: RouteRecordRaw[] = [{
    path: "teach",
    name: "H5Teach",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/teach/index.vue"),
}]

export default Teach
