import {RouteRecordRaw} from "vue-router";

const Register: RouteRecordRaw[] = [{
    path: "register/form",
    name: "H5RegisterForm",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/register/form/index.vue"),
}]

export default Register
