import {RouteRecordRaw} from "vue-router";

const Activity: RouteRecordRaw[] = [{
    path: "my/easy/activity/:tab?",
    name: "H5EasyActivity",
    component: () => import("@/page/h5/my/easy/activity/index.vue"),
}, {
    path: "my/easy/activity/edit/:uuid",
    name: "H5EasyActivityEdit",
    component: () => import("@/page/h5/my/easy/activity/edit/index.vue"),
}]

export default Activity
