
export default {
    nav: {
        subject: "聚会纪录"
    },
    field: {
        attend: {
            subject: "请选择聚会"
        }
    },
    edit: {
        nav: {
            subject: "聚会内容"
        },
        notice: {
            subject: "聚会尚未开始"
        },
        not: {
            this: {
                week: "本周无聚会"
            }
        },
        field: {
            begin: {
                subject: "开始时间"
            },
            end: {
                subject: "结束时间"
            },
            location: {
                subject: "地点"
            },
            note: {
                subject: "备注"
            },
            detail: {
                subject: "详细资讯",
                rollcall: {
                    subject: "点名单",
                    seq: {
                        subject: "序号"
                    },
                    uuid: {
                        subject: "识别码"
                    },
                },
                team: {
                    subject: "族群",
                    seq: {
                        subject: "序号"
                    },
                    uuid: {
                        subject: "识别码"
                    },
                    name: {
                        subject: "名称"
                    }
                },
                attend: {
                    subject: "聚会",
                    seq: {
                        subject: "序号"
                    },
                    uuid: {
                        subject: "识别码"
                    }
                },
                cycle: {
                    subject: "循环",
                    seq: {
                        subject: "序号"
                    }
                }
            }
        },
        text: {
            available: {
                subject: "应到会友"
            },
            multiple: {
                subject: "可复选"
            }
        },
        read: {
            subject: "发布时间 %s"
        },
        rank: {
            subject: "自评",
            not: {
                yet: {
                    subject: "未自评"
                }
            }
        },
        note: {
            subject: "留言",
            not: {
                yet: {
                    subject: "未留言"
                }
            }
        },
        register: {
            subject: "签到",
            not: {
                yet: {
                    subject: "未签到"
                }
            }
        },
        submit: {
            not: {
                this: {
                    week: "本周无聚会"
                }
            }
        }
    }
}
