import buy from "@/language/modules/page/article/activity/buy/zhCN"
import charge from "@/language/modules/page/article/activity/charge/zhCN"
import discount from "@/language/modules/page/article/activity/discount/zhCN"
import echelon from "@/language/modules/page/article/activity/echelon/zhCN"
import register from "@/language/modules/page/article/activity/register/zhCN"

export default {

    buy: buy,

    charge: charge,

    discount: discount,

    echelon: echelon,

    register: register,

    apply: {
        subject: `已有 <b class="ft-14 text-success">%s</b> 人报名`,
        left: {
            subject: `还有 <b class="ft-14 text-danger">%s</b> 个名额`,
        },
        full: {
            subject: `名额已满<br/>共计 <b class="ft-14 text-success">%s</b> 人报名`,
        }
    },

    note: {
        subject: "活动内容"
    }

}
