import {RouteRecordRaw} from "vue-router";

const Setting: RouteRecordRaw[] = [{
    path: "my/easy/setting",
    name: "H5EasySetting",
    component: () => import("@/page/h5/my/easy/setting/index.vue"),
}, {
    path: "my/easy/setting/theme",
    name: "H5EasySettingTheme",
    component: () => import("@/page/h5/my/easy/setting/theme/index.vue"),
}, {
    path: "my/easy/setting/font",
    name: "H5EasySettingFont",
    component: () => import("@/page/h5/my/easy/setting/font/index.vue"),
}, {
    path: "my/easy/setting/favorite",
    name: "H5EasySettingFavorite",
    component: () => import("@/page/h5/my/easy/setting/favorite/index.vue"),
}, {
    path: "my/easy/setting/black",
    name: "H5EasySettingBlack",
    component: () => import("@/page/h5/my/easy/setting/black/index.vue"),
}, {
    path: "my/easy/setting/slash",
    name: "H5EasySettingSlash",
    component: () => import("@/page/h5/my/easy/setting/slash/index.vue"),
}]

export default Setting
