export default {
    status: {
        0: {
            subject: "优惠已经逾期"
        },
        1: {
            subject: "优惠即将逾期, 请尽快报名"
        },
        2: {
            subject: "优惠尚未开始"
        }
    },
    subject: "报名费用",
    note: {
        subject: "优惠备注"
    },
    during: {
        subject: "%s 至 %s",
        first: {
            subject: "%s 之前(含)"
        },
        last: {
            subject: "%s 之后(含)"
        }
    }
}
