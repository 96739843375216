export default {
    edit: {
        signature: {
            nav: {
                subject: "簽到內容"
            },
            tab: {
                0: {
                    subject: "基本"
                },
                1: {
                    subject: "支付"
                }
            },
            error: {
                subject: "找不到對應的報到資訊",
                text: {
                    subject: "可能您沒有權限進行協助報到",
                    text: "請向教會管理人員聯繫或重新掃描二維碼"
                }
            },
            order: {
                success: {
                    subject: "手動設定\n已支付完成",
                },
                failure: {
                    subject: "手動設定\n已支付失敗",
                },
                status: {
                    change: {
                        subject: "手動設定已支付",
                        text: `即將跳過系統檢查標示為已經支付\n請確認後執行`
                    }
                }
            },
            submit: {
                subject: "即將確認入場",
                text: "請確定相關資料無誤後, 再進行入場確認",
                success: {
                    subject: "入場完成",
                },
                failure: {
                    subject: "入場失敗\n請向管理人員確認",
                },
                signature: {
                    off: {
                        subject: "未完成支付"
                    },
                    used: {
                        subject: "已經入場"
                    },
                    subject: "確認入場"
                }
            }
        },
        nav: {
            subject: "活動內容"
        },
        help: {
            download: {
                subject: "避免活動現場網路不穩定, 建議您下載二維碼到場使用"
            },
            used: {
                subject: "已經報到"
            },
            not: {
                paid: {
                    subject: "等待支付"
                }
            }
        },
        notice: {
            subject: "有部分報到等待支付",
            edit: {
                subject: "此報到目前正等待支付, 您可以先行下載",
            }
        },
        qrcode: {
            subject: "報到二維碼",
            text: "第 %s 位報名人員報到二維碼",
            preview: {
                show: {
                    subject: "顯示"
                },
                download: {
                    subject: "下載"
                },
                resend: {
                    subject: "發送"
                }
            },
        },
        echelon: {
            subject: "場次"
        },
        order: {
            subject: "自行支付",
            newebpay: {
                subject: "線上支付",
            },
            field: {
                amount: "付款金額",
                no: "訂單編號",
                submit: "付款時間",
                status: "訂單狀態"
            },
            status: {
                true: {
                    subject: "支付完成"
                },
                false: {
                    subject: "等待支付"
                }
            }
        },
        note: {
            subject: "活動須知",
            1: {
                subject: "二維碼報到成功後將會失效, 請勿將報到二維碼以任何形式轉發給非報名以外人員"
            },
            2: {
                subject: "您所繳納的活動費用將全數由『%s』收取, 愛無界協會不經手辦理"
            },
            3: {
                subject: "活動如有疑問或其他問題, 請洽教會負責人員"
            },
            4: {
                subject: "活動由『%s』舉辦並保留活動的解釋權利"
            },
            5: {
                subject: "線上支付服務由『藍新金流平台』提供"
            }
        },
    },
    nav: {
        subject: "我的活動"
    },
    tab: {
        all: "全部",
    },
    payment: {
        subject: "自行支付",
        newebpay: {
            subject: "線上支付",
        }
    },
    qrcode: {
        subject: "掃碼報到"
    },
    stamp: {
        subject: "報名時間"
    },
    status: {
        true: {
            subject: "完成報名"
        },
        false: {
            subject: "等待支付"
        }
    }
}
