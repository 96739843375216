import {
    createApp
} from 'vue'

import App from '@/App.vue'
import i18n from "@/language"
import pinia from "@/stores";
import router from "@/router";
import BootstrapVue3 from 'bootstrap-vue-3'
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import zhTw from 'element-plus/es/locale/lang/zh-tw';
import VueLazyload from 'vue-lazyload';

import {
    Divider,
    Form,
    Badge,
    Popup,
    ImagePreview,
    Swipe,
    SwipeItem,
    NavBar,
    PullRefresh,
    List,
    Cell,
    CellGroup,
    ConfigProvider,
    Col,
    Row,
    Icon,
    Toast,
    Tab,
    Tabs,
    Grid,
    GridItem,
    BackTop,
    Sticky,
    RadioGroup,
    Radio,
    Switch,
    Field,
    Button,
    Picker,
    PickerGroup,
    DatePicker,
    NoticeBar,
    Dialog,
    Tag,
    Loading,
    PasswordInput,
    NumberKeyboard,
    ActionSheet,
    Checkbox,
    CheckboxGroup,
    SwipeCell,
    Rate,
    Notify,
    Image as VanImage
} from 'vant'

import 'vant/lib/index.css'
import 'element-plus/dist/index.css'
import '@/style'

const app = createApp(App)

app.use(i18n)
   .use(router)
   .use(pinia)
   .use(Divider)
   .use(Tag)
   .use(Loading)
   .use(Form)
   .use(Notify)
   .use(Field)
   .use(Picker)
   .use(Dialog)
   .use(PickerGroup)
   .use(DatePicker)
   .use(NoticeBar)
   .use(Button)
   .use(Switch)
   .use(Sticky)
   .use(BackTop)
   .use(NavBar)
   .use(RadioGroup)
   .use(Radio)
   .use(List)
   .use(Grid)
   .use(GridItem)
   .use(Badge)
   .use(Popup)
   .use(Cell)
   .use(CellGroup)
   .use(BootstrapVue3)
   .use(ConfigProvider)
   .use(ImagePreview)
   .use(Col)
   .use(Row)
   .use(Icon)
   .use(VanImage)
   .use(Swipe)
   .use(Tab)
   .use(Tabs)
   .use(SwipeItem)
   .use(Toast)
   .use(PasswordInput)
   .use(NumberKeyboard)
   .use(ActionSheet)
   .use(Checkbox)
   .use(CheckboxGroup)
   .use(SwipeCell)
   .use(Rate)
   .use(VueLazyload, {
       preLoad: 1.3,
       listenEvents: ['scroll']
   })
   .use(PullRefresh)

const db = localStorage.getItem('SettingStore')
if( db !== null ) {
    const value = JSON.parse(db).region
    app.use(ElementPlus,{locale: value === 'zhTW' ? zhTw : zhCn})
}
else app.use(ElementPlus,{locale: zhTw})

//ElementPlusIcons
for (const [key, value] of Object.entries(ElementPlusIconsVue)) app.component(key, value)

app.mount('#app')

//export default app
