export default {
    nav: {
        subject: "个人资料"
    },
    section: {
        information: "个人资讯",
        contact: "联络资讯",
        other: "其他资讯"
    },
    empty: {
        subject: "未填写"
    },
    no: {
        nav: {
            subject: "会友编号"
        }
    },
    commitment: {
        nav: {
            subject: "委身编号"
        }
    },
    avatar: {
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    email: {
        nav: {
            subject: "电子邮件"
        }
    },
    passwd: {
        nav: {
            subject: "变更密码"
        },
        notice: {
            bar: {
                success: "密码一致",
                error: "密码不一致",
                failure: "请输入密码"
            }
        },
        holder: {
            new: {
                subject: "请输入新密码"
            },
            confirm: {
                subject: "请再输入一次密码进行确认"
            },
        },
        handler: {
            submit: {
                subject: "即将变更密码",
                text: "密码变更完成后将会注销App\n请您使用新密码登入"
            }
        },
        success: {
            subject: "密码变更成功",
            text: "即将注销App\n请您使用新密码登入"
        }
    },
    name: {
        nav: {
            subject: "姓名"
        },
        holder: {
            subject: "请输入姓名"
        },
        error: {
            1001: {
                subject: "姓名至少需要2个字",
                text: ""
            }
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    title: {
        nav: {
            subject: "称谓"
        },
        holder: {
            subject: "请选择称谓"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    birthday: {
        nav: {
            subject: "生日"
        },
        holder: {
            subject: "请输入生日"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    phone: {
        nav: {
            subject: "联络电话"
        },
        holder: {
            subject: "请输入联络电话"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    location: {
        nav: {
            subject: "所在区域"
        },
        holder: {
            subject: "请选择所在区域"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    address: {
        nav: {
            subject: "地址"
        },
        holder: {
            subject: "请输入地址"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    occupation: {
        nav: {
            subject: "职业"
        },
        holder: {
            subject: "请选择职业"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    baptized: {
        nav: {
            subject: "受洗时间"
        },
        holder: {
            subject: "请选择受洗时间"
        },
        success: {
            subject: "保存成功"
        },
        failure: {
            subject: "保存失败"
        }
    },
    delete: {
        subject: "删除帐户",
        handler: {
            subject: "删除帐户",
            text: "是否确定删除帐户\n删除后将无法复原, 请谨慎确认后执行",
            confirm: {
                subject: "最后确认",
                text: "您真的要删除帐户吗"
            }
        },
        success: {
            subject: "帐户删除完成",
            text: "期待您的再次使用"
        },
        failure: {
            subject: "删除失败"
        },
        submit: {
            submit: "残忍删除",
            cancel: "容我想想"
        }
    }
}
