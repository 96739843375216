import activity from "./activity/zhTW"
import setting from "./setting/zhTW"
import developer from "./developer/zhTW"
import feedback from "./feedback/zhTW"
import privacy from "./privacy/zhTW"
import donate from "./donate/zhTW"
import profile from "./profile/zhTW"
import belong from "./belong/zhTW"
import leader from "./leader/zhTW"

export default {
    activity: activity,
    leader: leader,
    belong: belong,
    profile: profile,
    donate: donate,
    privacy: privacy,
    feedback: feedback,
    setting: setting,
    developer: developer
}
