export default {
    nav: {
        subject: "设置"
    },
    entry: {
        theme: {
            subject: "主题"
        },
        pass: {
            subject: "通行码"
        },
        font: {
            subject: "字体大小"
        },
        locale: {
            subject: "语系"
        },
        favorite: {
            subject: "所有收藏文章留言通知"
        },
        bible: {
            subject: "圣经重新安装",
            refresh: {
                subject: "圣经重新安装",
                text: "即将重新安装圣经\n请确认后执行"
            },
            version: {
                subject: "圣经重新安装",
                text: "请先刷新您的App版本\n是否前往应用商店刷新"
            },
            success: {
                subject: "安装完成"
            }
        },
        black: {
            subject: "黑名单管理"
        },
        slash: {
            subject: "封锁名单"
        },
        storage: {
            subject: "储存空间管理"
        },
        logout: {
            subject: "注销"
        }
    },
    handler: {
        logout: {
            subject: "即将注销",
            text: "请确认后执行"
        }
    },
    font: {
        nav: {
            subject: "字体大小"
        },
        subject: "拖曳底部文字大小设置的滑块, 可以调整的字体大小",
        description: {
            subject: "设置成功后, 文章与圣经等页面的字体大小均会发生改变",
            text: "某些页面下, 字体过大可能会出现排版错乱的现象, 如果使用过程中出现问题, 请反馈给开发团队"
        }
    },
    pass: {
        enable: "开启",
        disable: "关闭"
    },
    favorite: {
        nav: {
            subject: "收藏留言通知"
        },
        subject: "所有收藏文章留言通知",
        text: "若关闭该功能后, 未来不会再接收到任何收藏文章的留言通知",
        enable: "开启",
        disable: "关闭",
        success: {
            subject: "更新成功"
        },
        failure: {
            subject: "更新失败"
        }
    },
    black: {
        nav: {
            subject: "黑名单"
        },
        success: {
            subject: "解除成功"
        },
        failure: {
            subject: "解除失败"
        },
        handler: {
            subject: "即将解除黑名单",
            text: "请确认后执行"
        }
    },
    slash: {
        nav: {
            subject: "封锁名单"
        },
        success: {
            remove: {
                subject: "解除成功"
            },
            delete: {
                subject: "删除成功"
            }
        },
        failure: {
            remove: {
                subject: "解除失败"
            },
            delete: {
                subject: "删除失败"
            }
        },
        submit: {
            remove: "解除封锁",
            delete: "删除"
        },
        handler: {
            remove: {
                subject: "即将解除封锁",
                text: "请确认后执行"
            },
            delete: {
                subject: "即将删除",
                text: "请确认后执行"
            }
        }
    },
    theme: {
        nav: {
            subject: "切换主题"
        },
        name: {
            light: "浅色",
            dark: "深色",
            follow: "跟随系统"
        },
        description: {
            subject: "自动跟随系统主题设置",
            text: "与手机保持一致的浅色或深色模式"
        }
    }
}
