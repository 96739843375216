export default {
    nav: {
        subject: "意见与建议"
    },
    subject: "内容",
    holder: "给教会宝贵的意见",
    success: {
        subject: "保存成功"
    },
    failure: {
        subject: "保存失败"
    }
}
