export default {
    nav: {
        subject: "聚会签到"
    },
    field: {
        attend: {
            subject: "请选择聚会"
        }
    },
    handler: {
        prevent: {
            subject: "本场聚会不能自行签到",
            text: "请向小组长扫码报到或扫描二维码报到"
        },
        signature: {
            subject: "即将进行聚会签到",
            text: "请确认您已到场后再后执行签到"
        }
    },
    success: {
        signature: {
            subject: "签到成功"
        }
    },
    failure: {
        signature: {
            subject: "签到失败"
        }
    }
}
