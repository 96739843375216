import {RouteRecordRaw} from "vue-router";

const Donate: RouteRecordRaw[] = [{
    path: "my/easy/donate",
    name: "H5EasyDonate",
    component: () => import("@/page/h5/my/easy/donate/index.vue"),
}, {
    path: "my/easy/donate/buy/:uuid",
    name: "H5EasyDonateBuy",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/my/easy/donate/buy/index.vue"),
}, {
    path: "my/easy/donate/completion/:uuid",
    name: "H5EasyDonateCompletion",
    meta: {
        right: false
    },
    component: () => import("@/page/h5/my/easy/donate/completion/index.vue"),
}, {
    path: "my/easy/donate/record/:tab?",
    name: "H5EasyDonateRecord",
    component: () => import("@/page/h5/my/easy/donate/record/index.vue"),
}, {
    path: "my/easy/donate/record/edit/:uuid",
    name: "H5EasyDonateRecordEdit",
    component: () => import("@/page/h5/my/easy/donate/record/edit/index.vue"),
}]

export default Donate
