<template>
  <i :class="props.hover ? 'hover' : ''"
     :data-type="props.name"
     :style="getStyle"
     class="vue-feather vue-feather--plus iconsax">
    <Add v-if="props.name==='add'"
         viewBox="0 0 24 24"
         :width="getSize"
         :height="getSize" />
    <Edit v-else-if="props.name==='edit'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <Trash v-else-if="props.name==='trash'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <Alarm v-else-if="props.name==='alarm'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <ArrowLeftRight v-else-if="props.name==='arrow-left-right'"
                    viewBox="0 0 24 24"
                    :width="getSize"
                    :height="getSize" />
    <ArrowLeft v-else-if="props.name==='arrow-left'"
               viewBox="0 0 24 24"
               :width="getSize"
               :height="getSize" />
    <Camera v-else-if="props.name==='camera'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <Copy v-else-if="props.name==='copy'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <EyeOff v-else-if="props.name==='eye-off'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <Eye v-else-if="props.name==='eye'"
         viewBox="0 0 24 24"
         :width="getSize"
         :height="getSize" />
    <Enable v-else-if="props.name==='enable'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <Flag2 v-else-if="props.name==='flag-2'"
             viewBox="0 0 24 24"
             :width="getSize"
             :height="getSize" />
    <Forward v-else-if="props.name==='forward'"
             viewBox="0 0 24 24"
             :width="getSize"
             :height="getSize" />
    <Login v-else-if="props.name==='login'"
          viewBox="0 0 24 24"
          :width="getSize"
          :height="getSize" />
    <Like v-else-if="props.name==='like'"
          viewBox="0 0 24 24"
          :width="getSize"
          :height="getSize" />
    <Message v-else-if="props.name==='message'"
             viewBox="0 0 24 24"
             :width="getSize"
             :height="getSize" />
    <Moon v-else-if="props.name==='moon'"
          viewBox="0 0 24 24"
          :width="getSize"
          :height="getSize" />
    <More v-else-if="props.name==='more'"
          viewBox="0 0 24 24"
          :width="getSize"
          :height="getSize" />
    <Refresh2 v-else-if="props.name==='refresh-2'"
              viewBox="0 0 24 24"
              :width="getSize"
              :height="getSize"/>
    <ScreenFull v-else-if="props.name==='screen-full'"
                viewBox="0 0 24 24"
                :width="getSize"
                :height="getSize" />
    <ScreenScale v-else-if="props.name==='screen-scale'"
                 viewBox="0 0 24 24"
                 :width="getSize"
                 :height="getSize" />
    <Smallcaps v-else-if="props.name==='smallcaps'"
               viewBox="0 0 24 24"
               :width="getSize"
               :height="getSize" />
    <Slash v-else-if="props.name==='slash'"
               viewBox="0 0 24 24"
               :width="getSize"
               :height="getSize" />
    <Star v-else-if="props.name==='star'"
          viewBox="0 0 24 24"
          :width="getSize"
          :height="getSize" />
    <Sun v-else-if="props.name==='sun'"
         viewBox="0 0 24 24"
         :width="getSize"
         :height="getSize" />
    <X v-else-if="props.name==='x'"
       viewBox="0 0 24 24"
       :width="getSize"
       :height="getSize" />
    <Profile v-else-if="props.name === 'profile'"
             viewBox="0 0 24 24"
             :width="getSize"
             :height="getSize" />
    <People v-else-if="props.name === 'people'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <Check v-else-if="props.name === 'check'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <Barcode v-else-if="props.name === 'barcode'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <CardPos v-else-if="props.name === 'card-pos'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <DiscountCircle v-else-if="props.name === 'discount-circle'"
             viewBox="0 0 24 24"
             :width="getSize"
             :height="getSize" />
    <Diagram v-else-if="props.name === 'diagram'"
                  viewBox="0 0 24 24"
                  :width="getSize"
                  :height="getSize" />
    <DollarSquare v-else-if="props.name === 'dollar-square'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <Paypal v-else-if="props.name === 'paypal'"
           viewBox="0 0 24 24"
           :width="getSize"
           :height="getSize" />
    <User v-else-if="props.name === 'user'"
            viewBox="0 0 24 24"
            :width="getSize"
            :height="getSize" />
    <Default v-else
             viewBox="0 0 24 24"
             :width="getSize"
             :height="getSize" />
  </i>
</template>

<script setup lang="ts">

import Profile from '@/assets/iconsax/profile.svg'
import People from '@/assets/iconsax/people.svg'
import Check from '@/assets/iconsax/check.svg'
import Add from '@/assets/iconsax/add.svg'
import Edit from '@/assets/iconsax/edit.svg'
import Trash from '@/assets/iconsax/trash.svg'
import Alarm from '@/assets/iconsax/alarm.svg'
import ArrowLeftRight from '@/assets/iconsax/arrow-left-right.svg'
import ArrowLeft from '@/assets/iconsax/arrow-left.svg'
import Camera from '@/assets/iconsax/camera.svg'
import Copy from '@/assets/iconsax/copy.svg'
import Default from '@/assets/iconsax/default.svg'
import Diagram from '@/assets/iconsax/diagram.svg'
import DiscountCircle from '@/assets/iconsax/discount-circle.svg'
import Eye from '@/assets/iconsax/eye.svg'
import EyeOff from '@/assets/iconsax/eye-off.svg'
import Enable from '@/assets/iconsax/enable.svg'
import Flag2 from '@/assets/iconsax/flag-2.svg'
import Forward from '@/assets/iconsax/forward.svg'
import Login from '@/assets/iconsax/login.svg'
import Like from '@/assets/iconsax/like.svg'
import Message from '@/assets/iconsax/message.svg'
import More from '@/assets/iconsax/more.svg'
import Moon from '@/assets/iconsax/moon.svg'
import Refresh2 from '@/assets/iconsax/refresh-2.svg'
import ScreenFull from '@/assets/iconsax/screen-full.svg'
import ScreenScale from '@/assets/iconsax/screen-scale.svg'
import Smallcaps from '@/assets/iconsax/smallcaps.svg'
import Slash from '@/assets/iconsax/slash.svg'
import Star from '@/assets/iconsax/star.svg'
import Sun from '@/assets/iconsax/sun.svg'
import Barcode from '@/assets/iconsax/barcode.svg'
import CardPos from '@/assets/iconsax/card-pos.svg'
import DollarSquare from '@/assets/iconsax/dollar-square.svg'
import Paypal from '@/assets/iconsax/paypal.svg'
import User from '@/assets/iconsax/user.svg'
import X from '@/assets/iconsax/x.svg'

import {computed} from "vue";

/**
 * ⚠️⚠️ 注意 ⚠️注意
 *
 * 如果需要用 width, height 控制大小, viewBox="0 0 24 24" 需要手動加上去
 * 就算svg檔案裡面有也沒用
 *
 */

const props = withDefaults(defineProps<{
  //圖名稱
  name: "discount-circle" | "diagram" | "login" | "refresh-2" | "flag-2" | "barcode" | "card-pos" | "dollar-square" | "paypal" | "trash" | "edit" | "check" | "people" | "profile" | "slash" | "copy" | "alarm" | "smallcaps" | "sun" | "moon" | "star" | "forward" | "more" | "message" | "arrow-left" | "like" | "x" | "eye-off" | "eye" | "camera" | "enable" | "screen-scale" | "screen-full" | "add" | "arrow-left-right",
  //滑過是否變色
  hover?: boolean
  size?: number | string
}>(), {
  hover: true
});

const getStyle = computed((): any => {
  if (!!props.size) return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  }
  return {}
})

const getSize = computed((): number | string => {
  if (!!props.size) return props.size
  return 24
})

</script>

<style scoped lang="sass">

@import '@/style/core/variables.sass'

.iconsax
  display: flex
  > svg
    position: relative
    top: -0.5px
  &.ft-10
    transform: scale(.42, .42)
    > svg
      transform: scale(.42, .42)
  &.ft-12
    transform: scale(.5, .5)
    > svg
      transform: scale(.5, .5)
  &.ft-14
    transform: scale(.6, .6)
    > svg
      transform: scale(.6, .6)
  &.ft-16
    transform: scale(.68, .68)
    > svg
      transform: scale(.68, .68)
  &.ft-18
    transform: scale(.75, .75)
    > svg
      transform: scale(.75, .75)
  &.ft-20
    transform: scale(.85, .85)
    > svg
      transform: scale(.85, .85)
  &.ft-22
    transform: scale(.92, .92)
    > svg
      transform: scale(.92, .92)
  &.ft-24
    transform: scale(1, 1)
    > svg
      transform: scale(1, 1)
  &.ft-32
    transform: scale(1.25, 1.25)
    > svg
      transform: scale(1.25, 1.25)
  &.ft-36
    transform: scale(1.5, 1.5)
    > svg
      transform: scale(1.5, 1.5)
  &.like-update-enable,
  &.favorite-update-enable
    svg
      fill: #F6C354
      stroke: #F6C354
  &.deg90
    transform: rotate(90deg)
  &.deg180
    transform: rotate(180deg)
  &.deg270
    transform: rotate(270deg)
</style>
