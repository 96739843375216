export default {
    subject: "报名费用",
    free: {
        subject: "免费报名"
    },
    original: {
        subject: "费用"
    },
    discount: {
        subject: "优惠费用"
    }
}
