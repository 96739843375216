import rank from "@/language/modules/page/attend/rank/zhTW"
import rollcall from "@/language/modules/page/attend/rollcall/zhTW"
import signature from "@/language/modules/page/attend/signature/zhTW"

export default {
    nav: {
        subject: "聚會內容"
    },
    field: {
        begin: {
            subject: "開始時間"
        },
        end: {
            subject: "結束時間"
        },
        location: {
            subject: "地點"
        },
        note: {
            subject: "備註"
        }
    },
    read: {
        subject: "發佈時間 %s"
    },
    edit: {
        register: {
            nav: {
                subject: "聚會簽到"
            },
            notice: {
                subject: "聚會需要在開始之後才可以進行點名"
            },
            read: {
                subject: "發佈時間 %s"
            },
            field: {
                begin: {
                    subject: "開始時間"
                },
                end: {
                    subject: "結束時間"
                },
                location: {
                    subject: "地點"
                },
                note: {
                    subject: "備註"
                }
            },
            submit: {
                register: "遞交簽到"
            },
            success: {
                subject: "遞交成功"
            },
            failure: {
                subject: "遞交失敗"
            },
            handler: {
                submit: {
                    subject: "即將進行遞交簽到",
                    text: "請確認後執行"
                }
            },
            client: {
                nav: {
                    subject: "聚會報到"
                },
                notice: {
                    subject: "聚會需要在開始之後才可以進行點名"
                },
                read: {
                    subject: "發佈時間 %s"
                },
                client: {
                    subject: "簽到會友"
                },
                field: {
                    begin: {
                        subject: "開始時間"
                    },
                    end: {
                        subject: "結束時間"
                    },
                    location: {
                        subject: "地點"
                    },
                    note: {
                        subject: "備註"
                    }
                },
                submit: {
                    register: "遞交簽到"
                },
                success: {
                    subject: "遞交成功"
                },
                failure: {
                    subject: "遞交失敗"
                },
                handler: {
                    submit: {
                        subject: "即將進行遞交簽到",
                        text: "請確認後執行"
                    }
                }
            }
        }
    },
    rank: rank,
    rollcall: rollcall,
    signature: signature
}
