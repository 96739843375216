import {RouteRecordRaw} from "vue-router";

const Attend: RouteRecordRaw[] = [{
    path: "attend/edit/:uuid",
    name: "H5Attend",
    component: () => import("@/page/h5/attend/edit.vue"),
}, {
    path: "attend/edit/:uuid/rollcall",
    name: "H5AttendRollCall",
    component: () => import("@/page/h5/attend/rollcall/index.vue"),
}, {
    path: "attend/edit/:uuid/rollcall/notify",
    name: "H5AttendRollCallNotify",
    component: () => import("@/page/h5/attend/rollcall/index.vue"),
}, {
    path: "attend/edit/:uuid/rollcall/edit/:uid",
    name: "H5AttendRollCallEdit",
    component: () => import("@/page/h5/attend/rollcall/edit/index.vue"),
}, {
    path: "attend/edit/:uuid/rank",
    name: "H5AttendRank",
    component: () => import("@/page/h5/attend/rank/index.vue"),
}, {
    path: "attend/edit/:uuid/rank/edit/:uid",
    name: "H5AttendRankEdit",
    component: () => import("@/page/h5/attend/rank/edit/index.vue"),
}, {
    //會友自行報到
    path: "attend/edit/:uuid/signature",
    name: "H5AttendSignature",
    component: () => import("@/page/h5/attend/signature/index.vue"),
}, {
    //會友掃小組長出示的二維碼報到
    path: "attend/edit/:uuid/register/:uid",
    name: "H5AttendRegister",
    component: () => import("@/page/h5/attend/register/index.vue"),
}, {
    //小組長掃會友二維碼
    path: "attend/edit/:uuid/register/:uid/client",
    name: "H5AttendRegisterClient",
    component: () => import("@/page/h5/attend/register/client/index.vue"),
}]

export default Attend
