export default {
    subject: "活动提交",
    method: {
        name: {
            1: {
                subject: "个人报名"
            },
            2: {
                subject: "团体报名"
            }
        },
        restrict: {
            subject: "剩余名额已经不足团体报名, 仅能进行个人报名"
        }
    },
    organization: {
        must: {
            subject: "必填"
        },
        empty: {
            subject: "未填写"
        },
        field: {
            name: {
                subject: "教会(机构)名称"
            },
            city: {
                subject: "教会(机构)所在城市"
            },
            contact: {
                name: {
                    subject: "联络人姓名"
                },
                phone: {
                    subject: "联络人电话"
                },
                email: {
                    subject: "联络人电子邮件"
                }
            },
            zip: {
                subject: "邮递区号"
            },
            address: {
                subject: "通讯地址"
            }
        },
        holder: {
            name: {
                subject: "请输入教会(机构)名称"
            },
            city: {
                subject: "请输入教会(机构)所在城市"
            },
            contact: {
                name: {
                    subject: "请输入联络人姓名"
                },
                phone: {
                    subject: "请输入联络人电话"
                },
                email: {
                    subject: "请输入联络人电子邮件"
                }
            },
            zip: {
                subject: "请输入邮递区号"
            },
            address: {
                subject: "请输入通讯地址"
            }
        },
        error: {
            email: {
                subject: "请输入正确的电子邮件格式"
            }
        }
    },
    condition: {
        must: {
            subject: "必填"
        },
        empty: {
            subject: "未填写"
        },
        holder: {
            boolean: {
                true: {
                    subject: "是"
                },
                false: {
                    subject: "否"
                }
            },
            text: {
                subject: "请输入"
            },
            select: {
                subject: "请选择"
            },
            append: {
                subject: "请输入%s的描述"
            }
        },
        error: {
            email: {
                subject: "请输入正确的电子邮件格式"
            }
        }
    },
    sheet: {
        field: {
            email: "电子邮件"
        },
        empty: {
            subject: "请先增加团体报名人员",
        },
        restrict: {
            subject: `团体报名人数最少 <b class="ft-16">%s</b> 位, 最多 <b class="ft-16">%s</b> 位`,
            left: {
                subject: `, 还需要 <b class="ft-16">%s</b> 位报名`
            },
            satisfy: {
                minimum: {
                    subject: `, 最多还可以报名 <b class="ft-16">%s</b> 位`
                },
                negative: {
                    subject: `, 已经达到活动报名人数上限`
                },
                maximum: {
                    subject: `已达到团报最高人数`
                }
            }
        },
        submit: {
            increase: {
                subject: "增加团报",
                text: "增加团体报名人员"
            }
        }
    },
    summary: {
        fieldset: {
            price: {
                subject: "费用",
                text: "合计 %s 位报名"
            },
            echelon: {
                subject: "场次"
            },
            organization: {
                subject: "团体报名资料"
            },
            data: {
                subject: "报名资料",
                text: "第 %s 位报名, 共 %s 位报名"
            }
        }
    },
    payment: {
        subject: "支付方式",
        method: {
            1: {
                subject: "信用卡"
            },
            2: {
                subject: "ATM"
            },
            3: {
                subject: "超商条码"
            },
            4: {
                subject: "银联卡"
            }
        }
    },
    handler: {
        subject: "即将放弃提交报名",
        text: "放弃后填写资料无法复原, 请确认后执行",
        payment: {
            subject: "即将前往蓝新金流进行线上支付",
            text: "确定要前往吗?"
        },
        success: {
            subject: "活动提交成功",
            text: null,
            query: {
                subject: "活动提交成功",
                text: `等待您的支付报名费用`,
                complete: {
                    subject: "活动提交成功",
                    text: "我们已经收到您线上支付的报名费用"
                }
            }
        }
    },
    error: {
        unknown: {
            subject: "线上支付异常"
        }
    }
}
