import refresh from "./refresh/zhCN"
import page from "./page/zhCN"
import submit from "./submit/zhCN"
import util from "./util/zhCN"
import share from "./share/zhCN"
import component from "./component/zhCN"

export default {

    /**
     * 刷新器
     */
    refresh: refresh,

    /**
     * 页面
     */
    page: {
        /**
         * 安装画面
         */
        launch: {
            subject: "请安装App用户端"
        },
        ...page
    },

    /**
     * 提交
     */
    submit: submit,

    /**
     * 工具
     */
    util: util,

    /**
     * 分享 - App
     */
    share: share,

    /**
     * 元件
     */
    component: component

}
