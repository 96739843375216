/**
 * 刷新器
 */
export default {
    maximum: {
        overflow: `搜索限制 <b class="text-danger">%s</b> 笔<br/>超出部分将自动截断`
    },
    header: {
        start: "下拉刷新",
        trigger: "松开刷新",
        loading: "正在读取中",
        complete: "读取完成",
        failure: "更新失败",
        success: "刷新完成",
    },
    footer: {
        complete: "读取完成",
        loading: "正在读取中",
        failure: "读取失败",
        no: {
            more: "已经到底了"
        }
    }
}
